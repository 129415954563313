import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField } from '@mui/material';
import adminAPI, { apis } from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { useSnackbar } from 'contexts/SnackbarContext';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import styles from './NfteForget.module.css';
const NFTEForget = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const organizationCode = location.pathname.split('/')[1];
  const [organizationMeta, setOrganizationMeta] = useState({});
  const { showSnackbar } = useSnackbar();

  const { call: sendRecoveryLink, isLoading: sendingRecoveryLink } = useApi({
    fetcher: (data) => apis.organizationUserForget(data),
  });

  const schema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  console.log('watch', errors);

  const handleForget = async (data) => {
    const { email } = data;

    const reqData = {
      email,
      organizationCode,
    };

    const response = await sendRecoveryLink(reqData); //adminAPI.organizationUserForget(data);

    if (response && !response.data.error) {
      showSnackbar('Recovery Email Sent', 'success');
      navigate(`/${organizationCode}/login`);
    }
  };

  const getOrganizationMeta = async () => {
    const response = await adminAPI.getOrganizationMetaByOrganizationMetaName(
      organizationCode
    );

    setOrganizationMeta(response.data);
  };

  useEffect(() => {
    getOrganizationMeta();
  }, []);

  return (
    <div className={styles.container}>
      {sendingRecoveryLink ? <LoadingOverlay></LoadingOverlay> : null}

      <div className={styles.login}>
        <div className={styles.login_container}>
          <img src={organizationMeta.loginLogo} alt="Logo" />
          <h1>Forget Password</h1>

          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 5,
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleForget)}
          >
            <TextField
              {...register('email')}
              error={!!errors['email']}
              helperText={errors['email'] ? errors['email'].message : ''}
              label="EMAIL ADDRESS"
              size="small"
            />

            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
            >
              Send Recovery Link
            </Button>
          </Box>

          <a className={styles.loginText} href={`/${organizationCode}/login`}>
            Back to Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default NFTEForget;
