const Spinner = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="274px"
        height="274px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect
            x="42.5"
            y="22.5"
            rx="7.5"
            ry="7.5"
            width="15"
            height="15"
            fill="#4c12a1"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1111111111111112s"
              begin="-0.9722222222222222s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(45 50 50)">
          <rect
            x="42.5"
            y="22.5"
            rx="7.5"
            ry="7.5"
            width="15"
            height="15"
            fill="#4c12a1"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1111111111111112s"
              begin="-0.8333333333333333s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(90 50 50)">
          <rect
            x="42.5"
            y="22.5"
            rx="7.5"
            ry="7.5"
            width="15"
            height="15"
            fill="#4c12a1"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1111111111111112s"
              begin="-0.6944444444444444s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(135 50 50)">
          <rect
            x="42.5"
            y="22.5"
            rx="7.5"
            ry="7.5"
            width="15"
            height="15"
            fill="#4c12a1"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1111111111111112s"
              begin="-0.5555555555555556s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(180 50 50)">
          <rect
            x="42.5"
            y="22.5"
            rx="7.5"
            ry="7.5"
            width="15"
            height="15"
            fill="#4c12a1"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1111111111111112s"
              begin="-0.41666666666666663s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(225 50 50)">
          <rect
            x="42.5"
            y="22.5"
            rx="7.5"
            ry="7.5"
            width="15"
            height="15"
            fill="#4c12a1"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1111111111111112s"
              begin="-0.2777777777777778s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(270 50 50)">
          <rect
            x="42.5"
            y="22.5"
            rx="7.5"
            ry="7.5"
            width="15"
            height="15"
            fill="#4c12a1"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1111111111111112s"
              begin="-0.1388888888888889s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(315 50 50)">
          <rect
            x="42.5"
            y="22.5"
            rx="7.5"
            ry="7.5"
            width="15"
            height="15"
            fill="#4c12a1"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1.1111111111111112s"
              begin="0s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
      </svg>
    </>
  );
};
const LoadingOverlay = () => {
  return (
    <div style={{ zIndex: 2147483647 }}>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 2147483647,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {/* Your custom loading image */}
          <Spinner style={{ width: 100, height: 100 }} />
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
