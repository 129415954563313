import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
const VerticalToggleIcon = () => (
  <svg
    width="18"
    height="145"
    viewBox="0 0 18 145"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <rect width="18" height="144" fill="white" />
    <path
      d="M17 1V2.186C17 4.308 16.1571 6.343 14.6569 7.843L3.34314 19.157C1.84285 20.657 1 22.692 1 24.814L1 122.186C1 124.308 1.84285 126.343 3.34315 127.843L14.6569 139.157C16.1571 140.657 17 142.692 17 144.814V145"
      stroke="#8B9190FF"
      className="mix-blend-multiply"
    />
  </svg>
);

const VerticalToggleButton = ({ isOpen, onChange }) => {
  const toggleButton = () => {
    onChange(!isOpen);
  };

  return (
    <Button
      sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
      onClick={toggleButton}
    >
      <Box
        sx={{
          width: '2px',
          height: '100%',
          backgroundColor: '#D2D4D4FF',
          marginRight: '-11px',
        }}
      />
      <IconButton
        sx={{
          position: 'absolute',
          top: 0,
          transform: 'translateX(-50%)',
          width: '20px',
          height: '100%',
          padding: 0,
        }}
        aria-label="Toggle Transcript"
      >
        <VerticalToggleIcon />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            writingMode: 'vertical-rl',
            transform: 'rotate(180deg)',
            fontSize: '8px',
            fontWeight: 300,
            color: isOpen ? '#8CA19F' : '#00706C',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {isOpen ? 'Hide Transcript' : 'Show Transcript'}
        </Typography>
        {isOpen ? (
          <ChevronRightIcon
            sx={{
              color: isOpen ? '#8CA19F' : '#00706C',
              fontSize: '15px',
              mt: '4px',
              zIndex: 1,
            }}
          />
        ) : (
          <ChevronLeftIcon
            sx={{
              color: isOpen ? '#8CA19F' : '#00706C',
              fontSize: '15px',
              mt: '4px',
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </Button>
  );
};

export default VerticalToggleButton;
