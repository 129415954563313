import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import apis from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { useUser } from 'pages/Settings/context';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useConvoDashboardStore } from 'store';
import { cleanObject, createSortedSelectOptions } from 'utils';
import EditDialogWrapper from '../common/EditDialogWrapper';
import { addTeam } from '../common/schema';
import { AvatarSelector } from './AvatarSelector';

const blankTeamInfo = {
  name: '',
  description: '',
  assignedReps: [],
  assignedPersonas: [],
};

const dummyTeamInfo = {
  totalReps: 0,
  totalAICalls: 0,
  totalCustomerCalls: 0,
  status: 'ACTIVE',
};

const TeamEditDialog = () => {
  const [teamInfo, setTeamInfo] = useState(blankTeamInfo);
  const { user } = useLoggedUser();

  const setTeamList = useConvoDashboardStore((state) => state.setTeamList);
  const teamList = useConvoDashboardStore((state) => state.teamList);
  const updateTeamItem = useConvoDashboardStore(
    (state) => state.updateTeamItem
  );
  const dialogState = useConvoDashboardStore((state) => state.editDialog);
  const changeDialogState = useConvoDashboardStore(
    (state) => state.setEditDialog
  );
  const { data } = dialogState;

  const { call: createTeam, isLoading: teamCreating } = useApi({
    fetcher: apis.postConvoTeam,
    successMessage: 'Team added successfully',
    onSuccess: (response) => {
      setTeamList([{ ...response, ...dummyTeamInfo }, ...teamList]);
      setTeamInfo(blankTeamInfo);
      changeDialogState({ isOpen: false, forItem: 'team' });
    },
  });

  const { call: updateTeam } = useApi({
    fetcher: apis.updateConvoTeam,
    successMessage: 'Team info updated successfully',
    onSuccess: (response) => {
      updateTeamItem(response);
      setTeamInfo(blankTeamInfo);
      changeDialogState({ isOpen: false, forItem: 'team' });
    },
  });

  const {
    data: SRList,
    get: fetchAllSR,
    isFetching: srLoading,
  } = useUser({ userType: 'SALES_REP' });

  const {
    data: personaList,
    call: fetchAllPersonas,
    isFetching: personasLoading,
  } = useApi({ fetcher: apis.getAllPersonas });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(addTeam),
    mode: 'onChange',
    defaultValues: { role: 'ADMIN' },
  });

  const mapAssignedData = (updatedInfo, SRList, personaList, avatar) => {
    return {
      ...cleanObject({ ...updatedInfo, avatar }),
      assignedReps: updatedInfo.assignedReps
        .map((id) => {
          const sr = SRList?.find((item) => item.id === id);
          return sr
            ? { id: sr.id, name: `${sr.firstName} ${sr.lastName}` }
            : null;
        })
        .filter(Boolean),
      assignedPersonas: updatedInfo.assignedPersonas
        .map((id) => {
          const persona = personaList?.find((item) => item._id === id);
          return persona ? { id: persona._id, name: persona.name } : null;
        })
        .filter(Boolean),
    };
  };

  const postTeamInfo = (updatedInfo) => {
    const mappedData = mapAssignedData(
      updatedInfo,
      SRList,
      personaList,
      dialogState.data.avatar
    );
    createTeam({ ...mappedData, organizationId: user.organization });
    reset();
  };

  const updateTeamInfo = async (updatedInfo) => {
    if (!dialogState.data) return;

    const mappedData = mapAssignedData(
      updatedInfo,
      SRList,
      personaList,
      dialogState.data.avatar
    );

    await updateTeam({ data: mappedData, teamId: data._id });
  };

  useEffect(() => {
    const selectedSRIds = data?.assignedReps?.map((item) => item.id);
    const selectedPersonaIds = data?.assignedPersonas?.map((item) => item.id);
    const initialTeamInfo = {
      name: data?.name || '',
      description: data?.description || '',
      assignedReps: selectedSRIds || [],
      assignedPersonas: selectedPersonaIds || [],
    };
    reset(initialTeamInfo);
    setTeamInfo(initialTeamInfo);
  }, [data, reset]);

  useEffect(() => {
    fetchAllSR();
    fetchAllPersonas();
  }, []);

  const renderFormFields = () => {
    const fields = [
      { name: 'name', label: 'Team Name', type: 'text' },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        multiline: true,
      },
      {
        name: 'assignedReps',
        label: 'Sales Representatives',
        type: 'multiSelect',
        options: createSortedSelectOptions(SRList ?? [], 'id', [
          'firstName',
          'lastName',
        ]),
      },
      {
        name: 'assignedPersonas',
        label: 'Assigned Personas',
        type: 'multiSelect',
        options: createSortedSelectOptions(personaList ?? [], '_id', 'name'),
      },
    ];

    return fields.map((item) => (
      <FormField
        loading={srLoading || personasLoading}
        key={item.name}
        field={item}
        register={register}
        errors={errors}
        watch={watch}
        state={teamInfo}
        stateUpdater={setTeamInfo}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
      />
    ));
  };

  return (
    <>
      {srLoading ? (
        <LoadingOverlay />
      ) : (
        <EditDialogWrapper
          onSubmit={handleSubmit(
            dialogState.dialogMode === 'edit' ? updateTeamInfo : postTeamInfo
          )}
          dialogType={'edit'}
          title={
            dialogState.dialogMode === 'edit' ? 'Update Team Info' : 'Add Team'
          }
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <AvatarSelector />
            {renderFormFields()}
          </Box>
        </EditDialogWrapper>
      )}
    </>
  );
};

export default TeamEditDialog;
