import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Login from '../components/Login';
import NFTEForget from '../pages/Forget/NFTEForget';
import NFTELogin from '../pages/Login/NFTELogin';
import NFTEReset from '../pages/Reset/NFTEReset';
import LearnerDashboard from '../pages/StudentView/Learner/Dashboard';

import LoginNew from '../TeacherView/login';
import CoachDashboard from '../components/CoachDashboard';
import CoachLogin from '../components/CoachLogin';
import NewQuestion from '../components/NewQuestion';
import Signup from '../components/Signup';
import ThankYouQuestion from '../components/ThankYouQuestion';
import UserDashboard from '../components/UserDashboard';
import StudentConversationalTranscript from '../pages/StudentView/ConversationalAI/Transcript';

import Announcements from '../Announcements/announcement';
import CoachPostQus from '../CoachQuery/coachpostQus';
import CoachQueryArchive from '../pages/CoachView/CoachQueryArchive/CoachQueryArchive';
import ProfileDetails from '../pages/ProfileDetails/profile';
import { Settings } from '../pages/Settings';
import NewQuery from '../pages/StudentView/NewQuery/newQuerry';
import PostQuestion from '../pages/StudentView/StudentQueryArchive/postQus';

import InsightView from '../pages/AdminView/Insight/Insight';
import InsightUserDetails from '../pages/AdminView/Insight/InsightUserDetails';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndCondition from '../pages/PrivacyPolicy/TermsCondition';
import Businesspage from '../pages/StudentView/Learner/Businesspage';

import CoachLeanCanvas from 'pages/CoachView/Learner/Canvas/Dashboard';
import TeacherAIDashboard from '../pages/AdminView/TeacherAI/Dashboard';
import TutorAI from '../pages/AdminView/TeacherAI/TutorAI';
import CoachAdmissionSubmission from '../pages/CoachView/Admission/AdmissionPage';
import CoachAdmissionDashboard from '../pages/CoachView/Admission/Dashboard';
import CoachAddmissionSubmitted from '../pages/CoachView/Admission/SubmittedAssessment';
import CoachAssistantAI from '../pages/CoachView/AssistantAI/AssistantAI';
import CoachDocumentAnalysisDashboard from '../pages/CoachView/DocumentAnalysis/Dashboard';
import CoachDocumentAnalysisView from '../pages/CoachView/DocumentAnalysis/View';
import CoachPsychAssessmentSummary from '../pages/CoachView/Psych/AssessmentSummary';
import CoachPsychCandidate from '../pages/CoachView/Psych/Candidate';
import CoachPsychClassSummary from '../pages/CoachView/Psych/ClassSummary';
import CoachPsychDashboard from '../pages/CoachView/Psych/Dashboard';
import CoachPsychDetails from '../pages/CoachView/Psych/Details';
import CoachResumeDashboard from '../pages/CoachView/ResumeAI/Dashboard';
import CoachResumeAnalysis from '../pages/CoachView/ResumeAI/ResumeAnalysis';
import StudentAdmissionSubmission from '../pages/StudentView/Admission/AdmissionPage';
import StudentAdmissionAssessment from '../pages/StudentView/Admission/Assessment';
import StudentAdmissionDashboard from '../pages/StudentView/Admission/Dashboard';
import StudentAddmissionSubmitted from '../pages/StudentView/Admission/SubmittedAssessment';
import StudentConversationalDashboard from '../pages/StudentView/ConversationalAI/Dashboard';
import StudentDocumentAnalysisDashboard from '../pages/StudentView/DocumentAnalysis/Dashboard';
import StudentNewDocumentAnalysisView from '../pages/StudentView/DocumentAnalysis/NewAnalysis';
import StudentResumeDashboard from '../pages/StudentView/ResumeAI/Dashboard';
import StudentResumeAnalysis from '../pages/StudentView/ResumeAI/ResumeAnalysis';

import Test from 'Test/Test';
import { Sidebar } from 'components/Sidebar';
import LogoutModal from 'components/Sidebar/LogoutModal';
import { useLoggedUser } from 'contexts/UserContext';
import AdminLeaderboard from 'pages/AdminView/AdminDashboard/Leaderboard';
import SRCallDetails from 'pages/AdminView/AdminDashboard/SRCallDetails';
import ViewSalesRepresentative from 'pages/AdminView/AdminDashboard/VIewSaleRepresentative';
import QueryDashboard from 'pages/CoachView/Learner/Canvas/Query';
import AIRoleplayCall from 'pages/ConvoAI/AiRoleplayCall';
import Assignment from 'pages/ConvoAI/Assignment';
import CallDetails from 'pages/ConvoAI/CallDetails';
import ConvoLeaderboard from 'pages/ConvoAI/ConvoLeaderboard';
import OngoingCall from 'pages/ConvoAI/OngoingCall';
import { ConvoDashboard } from 'pages/ConvoDashboard';
import { NotFound, OrganizationNotFound, Unauthorized } from 'pages/Errors';
import { useEffect } from 'react';
import { useAuthStore } from 'store';
import TeacherAIUpload from '../pages/AdminView/TeacherAI/Upload';
import StudentPsychAssessment from '../pages/StudentView/Psych/Assessment';
import StudentPsychAssessmentSummary from '../pages/StudentView/Psych/AssessmentSummary';
import StudentPsychDashboard from '../pages/StudentView/Psych/Dashboard';
import StudentPsychAssessmentThankYou from '../pages/StudentView/Psych/ThankYou';
import { ProtectedRoute } from './ProtectedRoute';

const OrgBasedDashboard = () => {
  const { user } = useLoggedUser();

  switch (user.organizationType) {
    case 'COMPETITION':
      return <Settings />;
    case 'CONVO':
      return <ConvoDashboard />;

    default:
      return <Settings />;
  }
};

export const AppRoutes = () => {
  const { isAuthenticated, initializeAuth } = useAuthStore();
  useEffect(() => {
    initializeAuth();
  }, []);

  return (
    <Router>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: '#f3f8f8',
          height: '100vh',
        }}
      >
        {isAuthenticated && <Sidebar />}
        <div
          className={`main-content ${
            isAuthenticated ? 'with-sidebar' : 'no-sidebar'
          }`}
        >
          <Routes>
            {/* Non protected routes */}
            <Route
              path="/"
              element={
                !isAuthenticated ? <OrganizationNotFound /> : <LogoutModal />
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/logout" element={<LogoutModal />} />
            <Route path="/loginnew" element={<LoginNew />} />
            <Route path="/coachLogin" element={<CoachLogin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-condition"
              element={<TermsAndCondition />}
            />
            <Route
              path="/:organization/login"
              element={!isAuthenticated ? <NFTELogin /> : <LogoutModal />}
            />
            <Route
              path="/:organization/forget"
              element={!isAuthenticated ? <NFTEForget /> : <Unauthorized />}
            />
            <Route
              path="/:organization/resetpassword"
              element={!isAuthenticated ? <NFTEReset /> : <Unauthorized />}
            />
            <Route path="/test" element={<Test />} />

            {/* Protected routes */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute
                  element={<OrgBasedDashboard />}
                  allowedRoles={['ADMIN', 'COACH', 'AGENT', 'SALES_REP']}
                />
              }
            />

            <Route
              path="/newQuestion"
              element={
                <ProtectedRoute
                  element={<NewQuestion />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/thankYouQuestion"
              element={
                <ProtectedRoute
                  element={<ThankYouQuestion />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/userDashboard"
              element={
                <ProtectedRoute
                  element={<UserDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coachDashboard"
              element={
                <ProtectedRoute
                  element={<CoachDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="announcement"
              element={
                <ProtectedRoute
                  element={<Announcements />}
                  allowedRoles={['*']}
                />
              }
            />

            <Route
              path="/admin/insights"
              element={
                <ProtectedRoute
                  element={<InsightView />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/admin/insights/user-details"
              element={
                <ProtectedRoute
                  element={<InsightUserDetails />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute
                  element={<ProfileDetails />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute
                  element={<ProfileDetails />}
                  allowedRoles={['*']}
                />
              }
            />

            <Route
              path="/student/new-query"
              element={
                <ProtectedRoute
                  element={<NewQuery />}
                  allowedRoles={['STUDENT', 'ADMIN']}
                />
              }
            />
            <Route
              path="/student/query-archive"
              element={
                <ProtectedRoute
                  element={<PostQuestion />}
                  allowedRoles={['STUDENT', 'ADMIN']}
                />
              }
            />
            <Route
              path="/student/learner/canvas"
              element={
                <ProtectedRoute
                  element={<LearnerDashboard />}
                  allowedRoles={['STUDENT', 'ADMIN']}
                />
              }
            />
            <Route
              path="/student/learner/canvas/:canvasId/"
              element={
                <ProtectedRoute
                  element={<Businesspage />}
                  allowedRoles={['STUDENT', 'ADMIN']}
                />
              }
            />
            {/* </Route> */}
            <Route
              path="/student/learner/business"
              element={
                <ProtectedRoute
                  element={<Businesspage />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/query-archive"
              element={
                <ProtectedRoute
                  element={<CoachQueryArchive />}
                  allowedRoles={['COACH', 'ADMIN']}
                />
              }
            />
            <Route
              path="/coachpostQus"
              element={
                <ProtectedRoute
                  element={<CoachPostQus />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/learner/canvas"
              element={
                <ProtectedRoute
                  element={<CoachLeanCanvas />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/admission/dashboard"
              element={
                <ProtectedRoute
                  element={<CoachAdmissionDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/admission/essay/:admissionId"
              element={
                <ProtectedRoute
                  element={<CoachAddmissionSubmitted />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/admission/submission/:admissionId"
              element={
                <ProtectedRoute
                  element={<CoachAdmissionSubmission />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/documentanalysis/dashboard"
              element={
                <ProtectedRoute
                  element={<CoachDocumentAnalysisDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/documentanalysis/view/:documentId"
              element={
                <ProtectedRoute
                  element={<CoachDocumentAnalysisView />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/admission/dashboard"
              element={
                <ProtectedRoute
                  element={<StudentAdmissionDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/admission/submission/:admissionId"
              element={
                <ProtectedRoute
                  element={<StudentAdmissionSubmission />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/admission/essay/:admissionId"
              element={
                <ProtectedRoute
                  element={<StudentAddmissionSubmitted />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/admission/essay/submit/:admissionId"
              element={
                <ProtectedRoute
                  element={<StudentAdmissionAssessment />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/documentanalysis/dashboard"
              element={
                <ProtectedRoute
                  element={<StudentDocumentAnalysisDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/documentanalysis/view/:documentId"
              element={
                <ProtectedRoute
                  element={<StudentNewDocumentAnalysisView />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/resumeanalysis/dashboard"
              element={
                <ProtectedRoute
                  element={<StudentResumeDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/resumeanalysis/view/:resumeId"
              element={
                <ProtectedRoute
                  element={<StudentResumeAnalysis />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/resumeanalysis/dashboard"
              element={
                <ProtectedRoute
                  element={<CoachResumeDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/resumeanalysis/view/:resumeId"
              element={
                <ProtectedRoute
                  element={<CoachResumeAnalysis />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/admin/psych/dashboard"
              element={
                <ProtectedRoute
                  element={<CoachPsychDashboard />}
                  allowedRoles={['ADMIN']}
                />
              }
            />
            <Route
              path="/admin/psych/details/:assessmentId"
              element={
                <ProtectedRoute
                  element={<CoachPsychDetails />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/admin/psych/candidates/:assessmentId"
              element={
                <ProtectedRoute
                  element={<CoachPsychCandidate />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/psych/dashboard"
              element={
                <ProtectedRoute
                  element={<CoachPsychDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/psych/details/:assessmentId"
              element={
                <ProtectedRoute
                  element={<CoachPsychDetails />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/psych/candidates/:assessmentId"
              element={
                <ProtectedRoute
                  element={<CoachPsychCandidate />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/assistantai"
              element={
                <ProtectedRoute
                  element={<CoachAssistantAI />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/psych/assessmentsummary/:studentId/:assessmentId"
              element={
                <ProtectedRoute
                  element={<CoachPsychAssessmentSummary />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/psych/classsummary/:studentId/:assessmentId"
              element={
                <ProtectedRoute
                  element={<CoachPsychClassSummary />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/psych/dashboard"
              element={
                <ProtectedRoute
                  element={<StudentPsychDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/psych/assessment/:assessmentId"
              element={
                <ProtectedRoute
                  element={<StudentPsychAssessment />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/psych/assessmentsummary/:studentId/:assessmentId"
              element={
                <ProtectedRoute
                  element={<StudentPsychAssessmentSummary />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/psych/assessment/:assessmentId/success"
              element={
                <ProtectedRoute
                  element={<StudentPsychAssessmentThankYou />}
                  allowedRoles={['*']}
                />
              }
            />

            <Route
              path="/admin/teacherai/dashboard"
              element={
                <ProtectedRoute
                  element={<TeacherAIDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/teacherai/tutor"
              element={
                <ProtectedRoute element={<TutorAI />} allowedRoles={['*']} />
              }
            />
            <Route
              path="/admin/teacherai/upload"
              element={
                <ProtectedRoute
                  element={<TeacherAIUpload />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/coversationalai/dashboard"
              element={
                <ProtectedRoute
                  element={<StudentConversationalDashboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/student/coversationalai/transcript"
              element={
                <ProtectedRoute
                  element={<StudentConversationalTranscript />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/coach/learner/canvas/:canvasId/"
              element={
                <ProtectedRoute
                  element={<QueryDashboard />}
                  allowedRoles={['*']}
                />
              }
            />

            <Route
              path="/coach/leaderboard/"
              element={
                <ProtectedRoute
                  element={<ConvoLeaderboard />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/sales_rep/leaderboard/"
              element={
                <ProtectedRoute
                  element={<ConvoLeaderboard />}
                  allowedRoles={['SALES_REP', 'ADMIN']}
                />
              }
            />
            <Route
              path="/admin/leaderboard/"
              element={
                <ProtectedRoute
                  element={<AdminLeaderboard />}
                  allowedRoles={['*']}
                />
              }
            />

            <Route
              path="/airoleplaycall"
              element={
                <ProtectedRoute
                  element={<AIRoleplayCall />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/airoleplaycall/ongoingcall/:personaId"
              element={
                <ProtectedRoute
                  element={<OngoingCall />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/airoleplaycall/calldetails"
              element={
                <ProtectedRoute
                  element={<CallDetails />}
                  allowedRoles={['*']}
                />
              }
            />

            <Route
              path="/view-sales-representative/"
              element={
                <ProtectedRoute
                  element={<ViewSalesRepresentative />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/sales-representative/call-details"
              element={
                <ProtectedRoute
                  element={<SRCallDetails />}
                  allowedRoles={['*']}
                />
              }
            />
            <Route
              path="/assignment/"
              element={
                <ProtectedRoute element={<Assignment />} allowedRoles={['*']} />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};
