import {
  Edit,
  HighlightOff,
  PersonOff,
  Replay,
  Search,
} from '@mui/icons-material';
import {
  Chip,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import { ContentBox } from 'components/Box';
import { ActionsDropdown, DataTable, TableSkeleton } from 'components/Table';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { useTableSearch, useUser } from 'pages/Settings/context';
import { useEffect } from 'react';
import { useConvoDashboardStore } from 'store';
import { getOriginalZonedDate } from 'utils/date';
import DeleteDialog from '../common/DeleteDialog';
import { AdminEditDialog } from './AdminEditDialog';

const AdminListTableColumns = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'invitationDate', label: 'Added On' },
  { id: 'status', label: 'Status' },
  { id: 'actions', label: 'Actions' },
];

export const AdminList = () => {
  const { user } = useLoggedUser();

  const adminList = useConvoDashboardStore((state) => state.adminList);
  const setAdminList = useConvoDashboardStore((state) => state.setAdminList);
  const changeEditDialogState = useConvoDashboardStore(
    (state) => state.setEditDialog
  );
  const changeDeleteDialogState = useConvoDashboardStore(
    (state) => state.setDeleteDialog
  );

  const { call: fetchUsers, isLoading: isFetching } = useApi({
    fetcher: () => {
      return apis.getUsersList(user.organization, 'ADMIN');
    },
    onSuccess: (updatedData) => setAdminList(updatedData),
  });

  const { remove, revoke, resend } = useUser({
    userType: 'ADMIN',
  });

  const searchFields = ['firstName', 'lastName', 'email', 'status'];
  const { searchTerm, setSearchTerm, filteredData } = useTableSearch({
    data: adminList,
    searchFields,
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const actions = (data) => {
    return [
      ...(data.status === 'ACCEPTED'
        ? [
            {
              label: 'View/Edit Details',
              icon: <Edit color="success" />,
              onClick: () =>
                changeEditDialogState({ isOpen: true, forItem: 'admin', data }),
            },
            {
              label: 'Remove Admin',
              icon: <PersonOff color="error" />,
              onClick: () =>
                changeDeleteDialogState({
                  isOpen: true,
                  forItem: 'admin',
                  onOk: async () => {
                    await remove({ userId: data.id, email: data.email });
                    const updatedAdminList = adminList.filter(
                      (admin) => admin.id !== data.id
                    );
                    setAdminList(updatedAdminList);
                  },
                }),
            },
          ]
        : []),
      ...(data.status === 'PENDING'
        ? [
            {
              label: 'Resend Request',
              icon: <Replay color="success" />,
              onClick: () =>
                resend({
                  organizationId: user.organization,
                  invitationId: data._id,
                }),
            },
            {
              label: 'Revoke Request',
              icon: <HighlightOff color="error" />,
              onClick: () =>
                changeDeleteDialogState({
                  isOpen: true,
                  forItem: 'admin',
                  onOk: async () => {
                    await revoke({
                      organizationId: user.organization,
                      invitationId: data.id,
                    });
                    const updatedAdminList = adminList.filter(
                      (admin) => admin.id !== data.id
                    );
                    setAdminList(updatedAdminList);
                  },
                }),
            },
          ]
        : []),
    ];
  };

  const adminSearchBar = (
    <Stack direction="row" spacing={1}>
      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  return (
    <ContentBox>
      <Stack direction="column" spacing={5}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Admin Roster</Typography>
          {adminSearchBar}
        </Stack>
        {isFetching ? (
          <TableSkeleton column={AdminListTableColumns} />
        ) : (
          <DataTable
            columns={AdminListTableColumns}
            sort={true}
            data={filteredData?.map((item) => ({
              ...item,
              actions: <ActionsDropdown actions={actions(item)} />,
              name: `${item.firstName} ${item.lastName}`,
              invitationDate: getOriginalZonedDate({
                date: item.invitationDate,
              }),
              status: (
                <Chip
                  label={item.status}
                  variant="outlined"
                  size="small"
                  color={item.status === 'ACCEPTED' ? 'success' : 'info'}
                />
              ),
            }))}
          />
        )}
      </Stack>
      <DeleteDialog />
      <AdminEditDialog />
    </ContentBox>
  );
};
