import { Logout } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useLoggedUser } from 'contexts/UserContext';
import { useDisclosure } from 'hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';

const LogoutModal = () => {
  const { user } = useLoggedUser();
  const logout = useAuthStore((state) => state.logout);
  const navigate = useNavigate();
  const { open, close, isOpen } = useDisclosure();

  const handleLogout = () => {
    logout();
    navigate(`/${user?.organizationName}/login`);
  };

  const handleCLose = () => {
    if (close) close();
    navigate(-1);
  };

  useEffect(() => {
    open();
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={handleCLose}
      maxWidth="xs"
      sx={{
        '& .MuiPaper-root': {
          padding: 2,
          borderRadius: 2,
          textAlign: 'center',
        },
      }}
    >
      <DialogTitle>
        <Logout
          color="error"
          sx={{
            fontSize: 50,
          }}
        />
        <Typography variant="h6" sx={{ mb: 1, textTransform: 'capitalize' }}>
          Confirm Logout
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Are you sure you want to log out? <br /> You will be redirected to the
          login page.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
        <Button
          fullWidth
          color="error"
          variant="contained"
          onClick={handleLogout}
          sx={{ fontWeight: 'bold' }}
        >
          Logout
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={handleCLose}
          sx={{ fontWeight: 'bold' }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;
