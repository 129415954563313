import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/paths';

import { TabBar } from './Tabs';
import { CourseProvider, DialogProvider } from './context';

export const Settings = () => {
  const navigate = useNavigate();
  const Header = (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Settings</Typography>
      <Button
        onClick={() => {
          navigate(ROUTES.DASHBOARD);
        }}
        variant="outlined"
        startIcon={<ArrowBackIosIcon />}
      >
        Return to dashboard
      </Button>
    </Stack>
  );

  return (
    <Stack direction={'column'} gap={5}>
      {Header}
      <Divider />
      <DialogProvider>
        <CourseProvider>
          <TabBar />
        </CourseProvider>
      </DialogProvider>
    </Stack>
  );
};

export default Settings;
