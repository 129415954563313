import DoneIcon from '@mui/icons-material/Done';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StartIcon from '@mui/icons-material/Start';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Person from '../images/person.png';
import './styles/coachpostQus.css';

const PersonReply = ({ name, reply }) => {
  return (
    <>
      <div className="person">
        <img src={Person} alt="Person" />
        <h3>{name}</h3>
      </div>

      <p>{reply}</p>
    </>
  );
};

const Question = ({ name, date }) => {
  const [data, setData] = React.useState([]);
  const [reply, setReply] = React.useState('');

  const [generate, setGenerate] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [star, setStar] = React.useState(false);
  const handleReply = (e) => {
    e.preventDefault();
    setData([...data, { name: 'Mr. Kene Turner', reply: reply }]);
    setReply('');
  };

  const handleRegenerate = (e) => {
    e.preventDefault();
    setReply(
      'Your answer is being generated. Please waitIn general, yes. Businesses pays taxes for government services. These include public trans- portation and highways, social programs, national defense, and assistance for business itself. Business payroll taxes fund workers’ insurance and retirement; unemployment taxes aid workers who have lost their jobs; sales taxes are collected on products and services sold and sent to state governments. Businesses pay income tax on their profits and property tax on buildings and inventory. Businesses can reduce their tax payments by taking deductions and using tax credits..'
    );
    setGenerate(true);
  };
  return (
    <div className="sub_container_right">
      <h2>{date}</h2>
      <div className="star">
        <h6>{name}</h6>

        {star ? (
          <StarIcon onClick={() => setStar(!star)} />
        ) : (
          <StarBorderIcon onClick={() => setStar(!star)} />
        )}
      </div>
      {data.length === 0 && (
        <p>
          Your question is currently being processed. You will receive a
          response from Mr. Kene Turner soon.
        </p>
      )}

      {data.length > 0 &&
        data.map((item) => <PersonReply name={item.name} reply={item.reply} />)}

      <div className="border"></div>

      {!confirm && (
        <>
          <h4>Post Reply</h4>
          <textarea
            className="reply_section"
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          >
            Your reply
          </textarea>
        </>
      )}
      <div className="bottom">
        <div className="bottom_section_button">
          {confirm ? (
            <p className="confirm_msg">
              <DoneIcon /> Thread confirmed and closed.
            </p>
          ) : (
            <>
              <button
                className={reply ? 'active' : 'reply'}
                onClick={handleReply}
                disabled={!reply}
              >
                <SendIcon />
                Post Reply
              </button>
              <button className={'regenerate'} onClick={handleRegenerate}>
                <RefreshIcon />
                Regenerate Answer
              </button>
            </>
          )}
        </div>
        {confirm ? (
          <p
            className="reopen"
            onClick={() => {
              setConfirm(false);
            }}
          >
            <StartIcon />
            Reopen Issue
          </p>
        ) : (
          <p className="report">
            <ReportGmailerrorredIcon /> Report Issue
          </p>
        )}
      </div>
    </div>
  );
};

const CoachPostQuestion = () => {
  const [selectedValue, setSelectedValue] = React.useState('All');
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [selectedQuestion, setSelectedQuestion] = React.useState([
    {
      name: 'Do I have to pay any taxes?',
      date: 'Tuesday • May 2, 2023 • 9:24 AM',
      isSelected: true,
    },
    {
      name: 'What is a competitive advantage?',
      date: 'Tuesday • May 2, 2023 • 9:24 AM',
      isSelected: false,
    },
  ]);

  const handleSelectedQuestion = (index) => {
    let temp = [...selectedQuestion];
    temp.map((item, i) => {
      if (i === index) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    });
    setSelectedQuestion(temp);
  };

  const navigate = useNavigate();

  const handleQuery = (e) => {
    e.preventDefault();
    navigate('/NewQuery');
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <div className="query__container__header">
        <h4>Coach Chat</h4>
        <div className="query_header__right">
          <FormControl className="buttons">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="All"
                control={<Radio />}
                label="All"
                onChange={handleChange}
              />
              <FormControlLabel
                value="Starred"
                control={<Radio />}
                label="Starred"
              />
              <FormControlLabel
                value="Closed"
                control={<Radio />}
                label="Closed"
              />
              <FormControlLabel value="Open" control={<Radio />} label="Open" />
            </RadioGroup>
          </FormControl>
          <TextField
            id="input-with-icon-textfield"
            label="Search"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <button onClick={handleQuery} className="add_query">
            <QuizOutlinedIcon /> New Chat
          </button>
        </div>
      </div>
      <div className="border"></div>

      <div className="query__container__body">
        <h4>Your Chat Archive</h4>
        <p>
          Welcome to your chat archive, featuring all your queries and their
          discussion threads.
        </p>
      </div>
      <div className="sub_container">
        <div className="sub_container_left">
          {selectedQuestion.map((item, index) => {
            if (item.isSelected) {
              return (
                <div
                  className="sub_container_left_bottom"
                  onClick={() => handleSelectedQuestion(index)}
                >
                  <p>{item.date}</p>
                  <h5>
                    {item.name.length > 60
                      ? item.name.substring(0, 60) + '...'
                      : item.name}
                  </h5>
                </div>
              );
            } else {
              return (
                <div
                  className="sub_container_left_top"
                  onClick={() => handleSelectedQuestion(index)}
                >
                  <p>{item.date}</p>

                  <h5>
                    {item.name.length > 60
                      ? item.name.substring(0, 60) + '...'
                      : item.name}
                  </h5>
                </div>
              );
            }
          })}
        </div>
        <div className="sub_container_right_section">
          {selectedQuestion.map((item, index) => {
            if (item.isSelected) {
              return (
                <Question
                  name={item.name}
                  date={item.date}
                  isSelected={item.isSelected}
                />
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default CoachPostQuestion;
