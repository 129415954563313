import {
  AdminPanelSettings,
  PeopleAlt,
  SupportAgent,
} from '@mui/icons-material';
import { Admins } from '../Admins';
import { Sales } from '../Sales';
import { TeamList } from '../Teams';
export const TabItems = [
  {
    label: 'Teams',
    value: 'teams',
    icon: <PeopleAlt />,
    content: <TeamList />,
  },
  {
    label: 'Sales Representatives',
    value: 'sales',
    icon: <SupportAgent />,
    content: <Sales />,
  },
  {
    label: 'Admins',
    value: 'admin',
    icon: <AdminPanelSettings />,
    content: <Admins />,
  },
];
