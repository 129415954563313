import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import organizationAPI from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { CustomTextField } from 'components/TextField';
import { useEffect, useState } from 'react';
import { BulbIcon, PersonIcon, QuestionIcon } from 'svg/CustomSVGIcon';
import styles from './styles/InsightUserDetails.module.css';

import { useLocation } from 'react-router-dom';

const CoachTable = ({ coaches, search }) => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Count</th>
          </tr>
        </thead>

        {coaches
          .filter((item) => {
            if (search === '') {
              return item;
            } else if (
              item.name.toLowerCase().includes(search.toLowerCase()) ||
              item.email.toLowerCase().includes(search.toLowerCase())
            ) {
              return item;
            }
          })
          .map((coach) => (
            <tr>
              <td>{coach.name}</td>
              <td>{coach.email}</td>
              <td>{coach.count}</td>
            </tr>
          ))}
      </table>
    </>
  );
};

const StudentTable = ({ students, search }) => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Count</th>
          </tr>
        </thead>

        {students
          .filter((item) => {
            if (search === '') {
              return item;
            } else if (
              item.name.toLowerCase().includes(search.toLowerCase()) ||
              item.email.toLowerCase().includes(search.toLowerCase())
            ) {
              return item;
            }
          })
          .map((student) => (
            <tr>
              <td>{student.name}</td>
              <td>{student.email}</td>
              <td>{student.count}</td>
            </tr>
          ))}
      </table>
    </>
  );
};

const QuestionTable = ({ questions, search }) => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Count</th>
          </tr>
        </thead>

        {questions
          .filter((item) => {
            if (search === '') {
              return item;
            } else if (
              item.question.toLowerCase().includes(search.toLowerCase())
            ) {
              return item;
            }
          })
          .map((question) => (
            <tr>
              <td>{question.question}</td>
              <td>{question.count}</td>
            </tr>
          ))}
      </table>
    </>
  );
};

const InsightUserDetails = () => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [top5Students, setTop5Students] = useState([]);
  const [top5Coaches, setTop5Coaches] = useState([]);
  const [top5Questions, setTop5Questions] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const active = queryParams.get('active');
  const [activeTab, setActiveTab] = useState(active || 'coaches');

  const getMe = async () => {
    console.log(active);
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
  };

  const getStats = async (course = null, region = null, dateRange = null) => {
    setLoading(true);
    try {
      const data = {};
      if (course) data.course = course;
      if (region) data.region = region;
      if (dateRange) data.duration = dateRange;
      data.limit = 'all';
      const response = await organizationAPI.getStatistics(data);
      setTop5Students(response.data.top5Students);
      setTop5Coaches(response.data.topCoaches);
      setTop5Questions(response.data.top5Questions);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getMe();
    getStats();
  }, []);

  return (
    <>
      {loading && <LoadingOverlay />}

      <div className={styles.header}>
        <h4>Rosters</h4>
      </div>
      <div className={styles.roster_section}>
        <div className={styles.roster_header}>
          <div className={styles.roster_nav}>
            <ul>
              <li
                className={activeTab === 'coaches' && styles.roster_nav_active}
                onClick={() => setActiveTab('coaches')}
              >
                <p>
                  <BulbIcon
                    fill={activeTab === 'coaches' ? '#0aa6e0' : '#8CA19F'}
                  />{' '}
                  Coaches
                </p>
              </li>
              <li
                className={activeTab === 'students' && styles.roster_nav_active}
                onClick={() => setActiveTab('students')}
              >
                <p>
                  <PersonIcon
                    fill={activeTab === 'students' ? '#0aa6e0' : '#8CA19F'}
                  />{' '}
                  Students
                </p>
              </li>
              <li
                className={
                  activeTab === 'questions' && styles.roster_nav_active
                }
                onClick={() => setActiveTab('questions')}
              >
                <p>
                  <QuestionIcon
                    fill={activeTab === 'questions' ? '#0aa6e0' : '#8CA19F'}
                  />{' '}
                  Questions
                </p>
              </li>
            </ul>
          </div>

          <CustomTextField
            id="input-with-icon-textfield"
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className={styles.roster_table}>
          {activeTab === 'coaches' && (
            <CoachTable coaches={top5Coaches} search={search} />
          )}
          {activeTab === 'students' && (
            <StudentTable students={top5Students} search={search} />
          )}
          {activeTab === 'questions' && (
            <QuestionTable questions={top5Questions} search={search} />
          )}
        </div>
      </div>
    </>
  );
};

export default InsightUserDetails;
