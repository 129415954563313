import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import LoadingOverlay from 'components/LoadingOverlay';
import {
  useCourseContext,
  useDialog,
  useUserContext,
} from 'pages/Settings/context';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { EditDialogWrapper, FormField } from '../../common';
import { schema } from '../validation/schema';

const blankCoachInfo = {
  firstName: '',
  lastName: '',
  email: '',
  course: '',
  school: '',
  region: '',
};

const EditDetailsDialogContent = ({ onClose }) => {
  const { closeDialog, dialogData } = useDialog();
  const [coachInfo, setCoachInfo] = useState(blankCoachInfo);

  const { update, isUpdating } = useUserContext();
  const {
    isFetching: courseIsFetching,
    regions,
    schools,
    options: courseOptions,
  } = useCourseContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(schema({ type: 'EDIT' })),
    mode: 'onChange',
    defaultValues: { role: 'COACH' },
  });

  const handleClose = () => {
    if (onClose) onClose();
    reset();
    closeDialog();
  };

  const updateCoachInfo = async (data) => {
    if (coachInfo) {
      const { firstName, lastName, email, region, school, course } = data;

      const courseNames = course.map(
        (courseId) =>
          courseOptions.find((option) => option.value === courseId)?.label ||
          courseId
      );

      const updatedCoachInfo = {
        firstName,
        lastName,
        email,
        region,
        school,
        course: courseNames.join(','),
      };
      await update({ userId: dialogData.id, data: updatedCoachInfo });
      reset();
      setCoachInfo(blankCoachInfo);
      handleClose();
    }
  };

  useEffect(() => {
    if (dialogData) {
      if (dialogData) {
        const courseArr = dialogData?.course?.split(',') || [];
        setCoachInfo((prev) => ({
          ...prev,
          course: courseArr,
        }));
        reset({ ...dialogData, course: courseArr });
      }
    }
  }, [dialogData, reset]);

  function renderFormFields() {
    const fields = [
      { name: 'firstName', label: 'First Name', type: 'text' },
      { name: 'lastName', label: 'Last Name', type: 'text' },
      { name: 'email', label: 'Email Address', type: 'text' },
      {
        name: 'region',
        label: 'Assigned Region',
        type: 'select',
        options: regions,
      },
      {
        name: 'school',
        label: 'School',
        type: 'select',
        options: schools,
      },
      {
        name: 'course',
        label: 'Assigned Courses',
        type: 'multiSelect',
        options: courseOptions,
      },
    ];

    return fields.map((field) => (
      <FormField
        key={field.name}
        field={field}
        register={register}
        errors={errors}
        watch={watch}
        state={coachInfo}
        stateUpdater={setCoachInfo}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
        loading={courseIsFetching}
      />
    ));
  }

  return (
    <>
      {isUpdating && <LoadingOverlay />}
      <EditDialogWrapper
        title={'Update Coach Info'}
        dialogType="edit"
        onSubmit={handleSubmit(updateCoachInfo)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {renderFormFields()}
        </Box>
      </EditDialogWrapper>
    </>
  );
};

export default EditDetailsDialogContent;
