import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const Message = ({ data }) => {
  const getScoreLabel = (score) => {
    if (score <= 33) return 1;
    if (score <= 66) return 2;
    return 3;
  };

  const getBarColor = (score) => {
    if (score <= 33) return 'red';
    if (score <= 66) return 'yellow';
    return 'green';
  };

  const score = data.aiGeneratedResponse?.score || 0;
  const scoreLabel = getScoreLabel(score);
  const barColor = getBarColor(score);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
        alignItems: 'flex-start',
        width: '98%',
        padding: '5px 10px',
        justifyContent:
          data.type === 'human-response' ? 'flex-end' : 'flex-start',
      }}
    >
      <Avatar
        src={data.userAvatar}
        alt={data.userAvatar}
        sx={{
          width: '24px',
          height: '24px',
          objectFit: 'cover',
          borderRadius: '50%',
          marginTop: '5px',
          order: data.type === 'human-response' ? 1 : 0,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          maxWidth: '70%',
          order: data.type === 'human-response' ? 0 : 1,
          width: '100%',
        }}
      >
        <Typography
          sx={{
            color: '#5b706f',
            fontFamily: 'Lexend',
            fontSize: '8px',
            fontWeight: 300,
            alignSelf:
              data.type === 'human-response' ? 'flex-end' : 'flex-start',
          }}
        >
          {data.time}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '8px',
            border: '1px solid #dae8e7',
            padding: '1px 10px',
            borderRadius: '5px',
            backgroundColor:
              data.type === 'human-response' ? '#5b706f' : '#f3f8f8',
            color: data.type === 'human-response' ? 'white' : '#5b706f',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Lexend',
              fontSize: '14px',
              fontWeight: 300,
              wordBreak: 'break-word',
              padding: '10px',
            }}
          >
            {data.message}
          </Typography>
          {data.aiGeneratedResponse && (
            <Box
              sx={{
                display: 'flex',
                width: '95%',
                alignItems: 'center',
                gap: '10px',
                padding: '10px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Lexend',
                  fontSize: '14px',
                  fontWeight: 300,
                  textWrap: 'nowrap',
                }}
              >
                RESPONSE SCORE
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: '#f3f8f8',
                  borderRadius: '10px',
                  padding: '1px',
                  height: '5px',
                  marginTop: '5px',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    backgroundColor: barColor,
                    borderRadius: '10px 0 0 10px',
                    transition: 'width 0.3s ease',
                    width: `${score}%`,
                  }}
                />
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Lexend',
                  fontSize: '14px',
                  fontWeight: 300,
                  textWrap: 'nowrap',
                }}
              >
                {scoreLabel}/3
              </Typography>
            </Box>
          )}
        </Box>
        {data.aiGeneratedResponse && (
          <Box
            sx={{
              border: '1px solid #dae8e7',
              padding: '10px 10px',
              borderRadius: '5px',
              backgroundColor: '#f3f8f8',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              alignItems: 'start',
              width: '97%',
            }}
          >
            <Button
              startIcon={<AutoAwesomeIcon />}
              sx={{
                display: 'flex',
                gap: '10px',
                backgroundColor: '#f3f8f8',
                border: '2px solid #2aa87e',
                borderRadius: '5px',
                padding: '10px',
                textWrap: 'nowrap',
                alignItems: 'center',
                width: 'fit-content',
                cursor: 'not-allowed',
                color: '#243030',
                '& .MuiButton-startIcon': {
                  marginRight: '4px',
                },
                '& .MuiTypography-root': {
                  fontFamily: 'Lexend',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  textWrap: 'nowrap',
                  lineHeight: 0,
                },
              }}
              disabled
            >
              <Typography>AI-Suggested Answer</Typography>
            </Button>
            <Typography
              sx={{
                fontFamily: 'Lexend',
                fontSize: '14px',
                fontWeight: 300,
                wordBreak: 'break-word',
                color: '#243030',
              }}
            >
              {data.aiGeneratedResponse.message}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Message;
