import LoadingOverlay from 'components/LoadingOverlay';
import { useSnackbar } from 'contexts/SnackbarContext';
import { useLoggedUser } from 'contexts/UserContext';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from 'store';

export const ProtectedRoute = ({ element, allowedRoles = [] }) => {
  const { showSnackbar } = useSnackbar();
  const { user } = useLoggedUser();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const loading = useAuthStore((state) => state.loading);

  const userRole = user?.role?.toLowerCase();

  const allowAllRoles = allowedRoles.includes('*');
  const formattedAllowedRoles = allowedRoles.map((role) => role.toLowerCase());

  const tryingNonAccessibleRoutes =
    !allowAllRoles &&
    allowedRoles.length &&
    !formattedAllowedRoles.includes(userRole);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (!isAuthenticated) {
    const lastVisitedOrgName = localStorage.getItem('organizationCode');
    const loginPath = lastVisitedOrgName
      ? `/${lastVisitedOrgName}/login`
      : '/unauthorized';
    return <Navigate to={loginPath} replace />;
  }

  if (tryingNonAccessibleRoutes) {
    showSnackbar('Not authorized', 'error');
    return <Navigate to="/unauthorized" replace />;
  }

  return element;
};
