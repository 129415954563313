import {
  Assignment,
  AutoAwesome,
  BarChart,
  Checklist,
  Dashboard,
  Insights,
  Logout,
  Palette,
  Phone,
  Plagiarism,
  QueryStats,
  School,
  Settings,
  SupportAgent,
} from '@mui/icons-material';
import { Avatar, Typography } from '@mui/material';
import { useAuthStore } from 'store';
import { DocumentAnalyzerNavIcon } from 'svg/CustomSVGIcon';

export const useSidebarItems = () => {
  const loggedUser = useAuthStore((state) => state.loggedUser);
  const loggedUserRole = loggedUser?.role.toLowerCase();
  const loggedUserName = `${loggedUser?.firstName ?? 'N'} ${
    loggedUser?.lastName ?? 'A'
  }`;

  const sidebarTopItems = [
    {
      id: 1,
      title: 'Dashboard',
      path: '/dashboard',
      icon: <Dashboard />,
      forWidget: ['adminDashboard', 'teamsConvo'],
    },
    {
      id: 2,
      title: 'Lean Canvas',
      path: `/${loggedUserRole}/learner/canvas`,
      icon: <Palette />,
      forWidget: ['canvas'],
    },
    {
      id: 3,
      title: 'Documents',
      path: `/${loggedUserRole}/documentanalysis/dashboard`,
      icon: <Plagiarism />,
      forWidget: ['documentAI'],
    },
    {
      id: 4,
      title: 'Resume',
      path: `/${loggedUserRole}/resumeanalysis/dashboard`,
      icon: <DocumentAnalyzerNavIcon />,
      forWidget: ['resumeAI'],
    },
    {
      id: 5,
      title: 'Chat',
      path: `/${loggedUserRole}/query-archive`,
      icon: <QueryStats />,
      forWidget: ['chat'],
    },
    {
      id: 6,
      title: 'Insights',
      path: `/${loggedUserRole}/insights`,
      icon: <Insights />,
      forWidget: ['insights'],
    },
    {
      id: 7,
      title: 'Essay',
      path: `/${loggedUserRole}/admission/dashboard`,
      icon: <School />,
      forWidget: ['essay'],
    },
    {
      id: 8,
      title: loggedUserRole !== 'admin' ? 'Workspace' : 'Leaderboard',
      path: `/${loggedUserRole}/leaderboard`,
      icon: <BarChart />,
      forWidget: ['leaderboard'],
    },
    {
      id: 9,
      title: 'Assignments',
      path: `/assignment`,
      icon: <Assignment />,
      forWidget: ['repAssignment'],
    },
    {
      id: 10,
      title: 'Start Call',
      path: `/airoleplaycall`,
      icon: <Phone />,
      forWidget: ['startCall'],
    },
    {
      id: 11,
      title: 'Teacher AI',
      path: `/${loggedUserRole}/teacherai/${
        loggedUserRole === 'admin' ? 'dashboard' : 'tutor'
      }`,
      icon: <AutoAwesome />,
      forWidget: ['teacherAI'],
    },
    {
      id: 12,
      title: 'Assessment',
      path: `/${loggedUserRole}/psych/dashboard`,
      icon: <Checklist />,
      forWidget: ['psychAI'],
    },
    {
      id: 13,
      title: 'Assistant AI',
      path: `/${loggedUserRole}/assistantai`,
      icon: <SupportAgent />,
      forWidget: ['assistantAI'],
    },
  ];

  const sidebarBottomItems = [
    {
      id: 1,
      title: 'Settings',
      path: '/settings',
      icon: <Settings />,
      forWidget: ['settings'],
    },
    {
      id: 2,
      title: loggedUserName,
      path: '/profile',
      icon: (
        <Avatar sx={{ bgcolor: 'success.main', width: 24, height: 24 }}>
          <Typography variant="caption">
            {`${loggedUserName.split(' ')[0][0]}${
              loggedUserName.split(' ')[1][0]
            }`}
          </Typography>
        </Avatar>
      ),
      forWidget: ['settings'],
    },
    {
      id: 3,
      title: 'Logout',
      path: '/logut',
      icon: <Logout />,
      forWidget: ['*'],
    },
  ];

  return { sidebarTopItems, sidebarBottomItems };
};
