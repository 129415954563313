import { Box, Button, Container, keyframes, Typography } from '@mui/material';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px); // Optional slight slide from top
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const OrganizationNotFound = () => {
  const handleContactAdmin = (e) => {
    e.preventDefault();
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h3"
        component="div"
        gutterBottom
        color="primary"
        sx={{
          fontWeight: 'bold',
          marginBottom: 4,
          letterSpacing: 2,
          animation: `${fadeIn} 1s ease-in-out`,
        }}
      >
        TIER ONE
      </Typography>

      <Box
        sx={{
          p: 4,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: '#f5f5f5',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Organization Not Recognized
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Please select a valid organization or reach out to your administrator
          for assistance.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleContactAdmin}
          sx={{
            mt: 3,
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        >
          Contact Admin
        </Button>
      </Box>
    </Container>
  );
};
