import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import organizationAPI from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { CustomLinearProgress } from 'components/Progress';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { DoneIcon, ProgressIcon } from 'svg/CustomSVGIcon';
import styles from '../../styles/LearnerDashboard.module.css';
import CanvasFilter from './CanvasFilter';

const CoachLeanCanvas = () => {
  const [loading, setLoading] = useState(false);
  const [rawCanvasList, setRawCanvasList] = useState([]);
  const [filteredCanvasList, setFilteredCanvasList] = useState([]);
  const user = useAuthStore((state) => state.loggedUser);
  const [activeFilters, setActiveFilters] = useState({});
  const navigate = useNavigate();

  const buildQueryString = (filters) => {
    if (!filters) return '';
    const queryParams = new URLSearchParams();

    if (filters.startDate) {
      queryParams.append(
        'startDate',
        moment(filters.startDate).startOf('day').format('YYYY-MM-DD')
      );
    }
    if (filters.endDate) {
      queryParams.append(
        'endDate',
        moment(filters.endDate).endOf('day').format('YYYY-MM-DD')
      );
    }

    return queryParams.toString();
  };

  const applyFilters = (data, filters) => {
    if (!filters || Object.keys(filters).length === 0) return data;

    return data.filter((canvas) => {
      if (filters.keyword?.trim()) {
        const keyword = filters.keyword.toLowerCase().trim();
        const canvasName = canvas.canvasName.toLowerCase();
        const fullName =
          `${canvas.userProfile.firstName} ${canvas.userProfile.lastName}`.toLowerCase();
        const course = canvas.userProfile.course.toLowerCase();

        if (
          !canvasName.includes(keyword) &&
          !fullName.includes(keyword) &&
          !course.includes(keyword)
        ) {
          return false;
        }
      }

      if (filters.minScore || filters.maxScore) {
        const lastGrading = canvas.gradings[canvas.gradings.length - 1];
        if (!lastGrading) return false;

        const overallTile = lastGrading.tiles.find(
          (tile) => tile.name === 'overall'
        );
        if (!overallTile) return false;

        const score = overallTile.score;
        if (filters.minScore && score < filters.minScore) return false;
        if (filters.maxScore && score > filters.maxScore) return false;
      }

      if (filters.progressMin || filters.progressMax) {
        const progress = canvas.progressPercentage;
        if (filters.progressMin && progress < filters.progressMin) return false;
        if (filters.progressMax && progress > filters.progressMax) return false;
      }

      if (filters.courses?.length) {
        const selectedCourses = filters.courses.map((course) =>
          typeof course === 'string'
            ? course.toLowerCase()
            : course.name.toLowerCase()
        );
        if (
          !selectedCourses.includes(canvas.userProfile.course.toLowerCase())
        ) {
          return false;
        }
      }

      if (filters.status) {
        if (canvas.status.toLowerCase() !== filters.status.toLowerCase()) {
          return false;
        }
      }

      return true;
    });
  };

  const getAllCanvas = async (filters = null) => {
    setLoading(true);
    try {
      const queryString = buildQueryString(filters);
      const response = await organizationAPI.getAllCanvasForCoach(queryString);
      setRawCanvasList(response.data);

      const filteredData = applyFilters(response.data, filters);
      setFilteredCanvasList(filteredData);
    } catch (error) {
      console.error('Error fetching canvas data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      const dateFilters = {
        startDate: activeFilters.startDate,
        endDate: activeFilters.endDate,
      };
      getAllCanvas(dateFilters);
    }
  }, [user, activeFilters.startDate, activeFilters.endDate]);

  useEffect(() => {
    const filteredData = applyFilters(rawCanvasList, activeFilters);
    setFilteredCanvasList(filteredData);
  }, [rawCanvasList, activeFilters]);

  const handleFilterChange = (updatedFilters) => {
    setActiveFilters(updatedFilters);
  };

  const convertDate = (dateString) => {
    return moment(dateString).format('dddd • MMMM D, YYYY');
  };

  const handleCanvasNavigation = (canvasId) => (e) => {
    e.preventDefault();
    navigate(`/coach/learner/canvas/${canvasId}`);
  };

  const renderScore = (gradings) => {
    if (!gradings?.length) return 'Not Graded Yet';
    const lastGrading = gradings[gradings.length - 1];
    const overallTile = lastGrading.tiles.find(
      (tile) => tile.name === 'overall'
    );
    return overallTile ? overallTile.score : 'Not Graded Yet';
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <h1>Welcome, {user ? `${user.firstName} ${user.lastName}` : ''}</h1>

      <CanvasFilter onFilterChange={handleFilterChange} />

      <div className={styles.coach_roaster}>
        <div className={styles.learner_table}>
          <table className="styled_table">
            <thead className="table_headers">
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th>Canvas Name</th>
                <th>Student Name</th>
                <th>Course</th>
                <th>Score</th>
                <th>Progress</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCanvasList.map((item, index) => (
                <tr className={styles.coachrow} key={item._id || index}>
                  <td className={styles.data}>{convertDate(item.createdAt)}</td>
                  <td className={styles.data}>
                    <div className={`${styles[item.status]}`}>
                      {item.status === 'progress' ? (
                        ''
                      ) : item.status === 'review' ? (
                        <ProgressIcon />
                      ) : (
                        <DoneIcon />
                      )}
                      {item.status.toUpperCase()}
                    </div>
                  </td>
                  <td className={styles.data}>{item.canvasName}</td>
                  <td className={styles.data}>
                    {`${item.userProfile.firstName} ${item.userProfile.lastName}`}
                  </td>
                  <td className={styles.data}>{item.userProfile.course}</td>
                  <td className={styles.data}>{renderScore(item.gradings)}</td>
                  <td className={styles.data}>
                    <CustomLinearProgress value={item.progressPercentage} />
                  </td>
                  <td className={styles.data}>
                    <div className={styles.status}>
                      <div className={styles.resend}>
                        <VisibilityOutlinedIcon />
                        <button onClick={handleCanvasNavigation(item._id)}>
                          View Details
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {filteredCanvasList.length === 0 && (
            <div className={styles.empty_table}>
              <p>No Business Canvas listed yet.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CoachLeanCanvas;
