import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, IconButton, Popover, TextField } from '@mui/material';
import { format } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { DateRange as DRP } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
export const DateRangePicker = forwardRef(
  (
    { onDateChange, onClear, isLabel = true, width = '150px', size = 'small' },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [inputValue, setInputValue] = useState({
      startDate: '',
      endDate: '',
    });
    const [dateRange, setDateRange] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);

    useEffect(() => {
      if (!inputValue.startDate && !inputValue.endDate) {
        const today = new Date();
        setDateRange([
          {
            startDate: today,
            endDate: today,
            key: 'selection',
          },
        ]);
      }
    }, [inputValue, onDateChange]);

    const resetDateRange = useCallback(() => {
      const today = new Date();

      setInputValue({
        startDate: '',
        endDate: '',
      });

      const resetRange = [
        {
          startDate: today,
          endDate: today,
          key: 'selection',
        },
      ];
      setDateRange(resetRange);

      if (onDateChange) {
        onDateChange(resetRange[0]);
      }
    }, [onDateChange]);

    useImperativeHandle(ref, () => ({
      reset: resetDateRange,
    }));

    useEffect(() => {
      if (onClear) {
        onClear(resetDateRange);
      }
    }, [onClear, resetDateRange]);

    const handleSelect = (ranges) => {
      const { startDate, endDate } = ranges.selection;

      setInputValue({
        startDate: format(startDate, 'MM/dd/yyyy'),
        endDate: format(endDate, 'MM/dd/yyyy'),
      });

      setDateRange([ranges.selection]);
      if (onDateChange) {
        onDateChange(ranges.selection);
      }
    };

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    // Clear start date
    const clearStartDate = () => {
      setInputValue((p) => ({ ...p, startDate: '' }));
      setDateRange([{ ...dateRange[0], startDate: dateRange[0].endDate }]);
      if (onDateChange) {
        onDateChange(dateRange);
      }
    };

    // Clear end date
    const clearEndDate = () => {
      setInputValue((p) => ({ ...p, endDate: '' }));
      setDateRange([{ ...dateRange[0], endDate: dateRange[0].startDate }]);
      if (onDateChange) {
        onDateChange(dateRange);
      }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'date-range-picker-popover' : undefined;

    return (
      <Box display="flex" alignItems="center">
        {/* Start Date Input */}
        <TextField
          size={size}
          label={isLabel ? 'Start Date' : null}
          placeholder={!isLabel ? 'Start Date' : ''}
          onClick={handleClick}
          value={inputValue.startDate}
          InputProps={{
            endAdornment: !inputValue.startDate ? (
              <IconButton size="small" onClick={handleClick}>
                <CalendarTodayIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton size="small" onClick={clearStartDate}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          readOnly
          sx={{ width: width }}
        />

        {/* End Date Input */}
        <Box mx={1}> - </Box>
        <TextField
          size={size}
          label={isLabel ? 'End Date' : null}
          placeholder={!isLabel ? 'End Date' : ''}
          onClick={handleClick}
          value={inputValue.endDate}
          InputProps={{
            endAdornment: !inputValue.endDate ? (
              <IconButton size="small" onClick={handleClick}>
                <CalendarTodayIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton size="small" onClick={clearEndDate}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          readOnly
          sx={{ width: width }}
        />

        {/* Popover for Date Range Picker */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <DRP
            editableDateInputs={true}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            locale={enUS}
          />
        </Popover>
      </Box>
    );
  }
);

export default DateRangePicker;
