import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import organizationAPI from 'api/api';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './styles/AssessmentSummary.module.css';

import LoadingOverlay from 'components/LoadingOverlay';
import { useAssessmentStore } from 'store';

import { ArrowOutward, Checklist, ChevronLeft } from '@mui/icons-material';
import { useLoggedUser } from 'contexts/UserContext';
import { ToastContainer } from 'react-toastify';

const Tips = ({ assessmentAnswer }) => {
  return (
    <div className={styles.grade_section}>
      {assessmentAnswer?.map((section) => (
        <div className={styles.assessment_answer_list}>
          <div className={styles.assessment_tip}>
            <h2 className={styles.review_title_h2}>
              {`${section.question}`}
              <ArrowOutward />
            </h2>

            <p>{section.tipText}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const AssessmentAnswer = ({ assessmentAnswer }) => {
  const [groupedBySection, setGroupedBySection] = useState(null);

  useEffect(() => {
    if (assessmentAnswer) {
      const grouped = assessmentAnswer.reduce((acc, question) => {
        const section = acc.find(
          (item) => item.sectionName === question.sectionName
        );
        if (section) {
          section.questions.push(question);
        } else {
          acc.push({
            sectionName: question.sectionName,
            questions: [question],
          });
        }
        return acc;
      }, []);
      setGroupedBySection(grouped);
      console.log(grouped);
    }
  }, [assessmentAnswer]);
  return (
    <div className={styles.grade_section}>
      {groupedBySection &&
        groupedBySection.map((section) => (
          <div className={styles.assessment_answer_list}>
            <div className={styles.assessment_body}>
              <div className={styles.assement_answer_header}>
                <h3>{section.sectionName}</h3>
                {/* <p>Score Subtotal: 17/20</p> */}
              </div>
              <div className={styles.border}></div>
            </div>
            {section.questions.map((question, index) => (
              <div className={styles.assessment_mcq}>
                <h2 className={styles.review_title_h2}>
                  {`${index + 1}. ${question.questionName}`}
                </h2>

                <FormControl className={styles.mcq_radio} disabled>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={question.selectedAnswer}
                  >
                    {question.options.map((option, index) => (
                      <FormControlLabel
                        value={option}
                        control={<Radio size="small" />}
                        label={option}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

const GradeSummary = ({ gradeSummary }) => {
  return (
    <div className={styles.grade_section}>
      <div className={styles.grade_section_header_card}>
        <div className={styles.header_card_left}>
          <div>
            <h5>CANDIDATE</h5>
            <p>{`${gradeSummary?.user.firstName} ${gradeSummary?.user.lastName}`}</p>
            <span>{gradeSummary?.user.email}</span>
          </div>

          <div>
            <h5>TOTAL SCORE</h5>
            <p>
              {gradeSummary?.totalScore}/{gradeSummary?.maxTotalScore}
            </p>
          </div>
        </div>
        <div className={styles.header_card_right}>
          <h5>RESULT</h5>
          <p>{gradeSummary?.scoringBracket?.title}</p>
          <h4>{gradeSummary?.scoringBracket?.description}</h4>
        </div>
      </div>

      <div className={styles.grade_section_body}>
        {gradeSummary?.sectionScores.map((section, index) => (
          <>
            <div className={styles.grade_body_item}>
              <div className={styles.grade_body_item_header}>
                <h5>{section.sectionScoringBracket?.title}</h5>
                <p>
                  Score Subtotal: {section.sectionScore}/
                  {section.sectionMaxScore}
                </p>
              </div>
              <p>{section.sectionScoringBracket?.description}</p>
            </div>
            {index !== gradeSummary.sectionScores.length - 1 && (
              <div className={styles.border}></div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { selectedSummarytab } = useAssessmentStore();
  const [activeMenu, setActiveMenu] = useState(selectedSummarytab ?? 'answer');
  const [gradeSummary, setGradeSummary] = useState(null);
  const [tips, setTips] = useState(null);

  const location = useLocation();
  const assessmentId = location.pathname.split('/')[5];
  const studentId = location.pathname.split('/')[4];
  const user = useLoggedUser();
  const handleCandidateNavigation = (e) => {
    navigate(`/student/psych/dashboard`);
  };

  const getAssessmentTips = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAssessmentStudentTips(
        assessmentId
      );
      setTips(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getAssessmentSummary = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAssessmentDetailsStudent(
        assessmentId
      );

      setGradeSummary(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAssessmentSummary();
    getAssessmentTips();
  }, []);

  return (
    <>
      {loading && <LoadingOverlay />}

      <div className={styles.content}>
        <div className={styles.learner_header}>
          <h1>View Assessment</h1>

          <div className={styles.learner_header_buttons}>
            <button className={styles.back} onClick={handleCandidateNavigation}>
              <ChevronLeft />
              Return to Dashboard
            </button>
            {/* <button className={styles.summarize_button}>
                <Refresh />
                Enable Retaking
              </button> */}
          </div>
        </div>
        <div className={styles.border}></div>
        {/* <div className={styles.assessment_summary_header}>
            <CustomTextField
              label="Assessment Name"
              value={`${gradeSummary?.assessmentName}`}
              readOnly
              fullWidth
            />
            <CustomTextField
              label="Created By"
              value={`Peter Pan`}
              fullWidth
              readOnly
            />
          </div> */}

        <div className={styles.assessment_summary_nav}>
          <ul>
            {/* <li
                className={activeMenu === 'grade' ? styles.active : ''}
                onClick={() => setActiveMenu('grade')}
              >
                <Assessment />
                <p>Grade Summary</p>
              </li> */}
            <li
              className={activeMenu === 'answer' ? styles.active : ''}
              onClick={() => setActiveMenu('answer')}
            >
              <Checklist />
              <p>Assessment Answers</p>
            </li>
            <li
              className={activeMenu === 'tip' ? styles.active : ''}
              onClick={() => setActiveMenu('tip')}
            >
              <Checklist />
              <p>Tips</p>
            </li>
          </ul>
        </div>

        {activeMenu === 'grade' && (
          <GradeSummary gradeSummary={gradeSummary?.scores} />
        )}
        {activeMenu === 'answer' && (
          <AssessmentAnswer assessmentAnswer={gradeSummary?.questions} />
        )}
        {activeMenu === 'tip' && <Tips assessmentAnswer={tips} />}
      </div>
      <ToastContainer />
    </>
  );
};

export default Dashboard;
