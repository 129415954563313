import { Box } from '@mui/material';

import { Tab } from 'components/Tab';
import { TabItems } from './const';

export const TabBar = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Tab tabItems={TabItems} />
    </Box>
  );
};
