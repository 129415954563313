import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styles from './styles/Assessment.module.css';

import { useLocation, useNavigate } from 'react-router-dom';

import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  LinearProgress,
  Modal,
  TextField,
} from '@mui/material';
import organizationAPI from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { SelectGradeStatus } from 'components/SelectField';
import { CustomTextField } from 'components/TextField';
import {
  DoneIcon,
  PendingIcon,
  ProgressIcon,
  RemoveModalIcon,
} from 'svg/CustomSVGIcon';

const PossibilitySection = ({
  possibilityEdit,
  possibilityData,
  essayImprovementSuggestions,
  setEssayImprovementSuggestions,
  getEssayById,
  setLoading,
}) => {
  const [open, setOpen] = React.useState(false);
  const [removeId, setRemoveId] = React.useState('');
  function formatString(str) {
    // Split string by capital letters
    let temp = str.charAt(0).toUpperCase() + str.slice(1);
    let words = temp.match(/[A-Z][a-z]*/g);

    // Join the words with spaces
    let formattedString = words.join(' ');

    return formattedString;
  }
  const handleRemove = async (e, id) => {
    e.preventDefault();
    setOpen(false);
    setLoading(true);
    const data = essayImprovementSuggestions.filter(
      (item) => item._id !== removeId
    );

    try {
      const response = await organizationAPI.updateEssayImprovementSuggestions(
        id,
        data
      );
      console.log(response);
      await getEssayById();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <>
      {!possibilityEdit ? (
        possibilityData.essayImprovementSuggestions.map((item) => {
          return (
            <div className={styles.score_body}>
              <div className={styles.score_detail}>
                <div className={styles.score_detail_left}>
                  <h2>{formatString(item.improvementArea)}</h2>
                </div>
              </div>
              <p>{item.suggestion}</p>
            </div>
          );
        })
      ) : (
        <>
          <div
            className={styles.add}
            onClick={() => {
              console.log(crypto.randomUUID());
              let temp = [
                {
                  improvementArea: 'test',
                  suggestion: '',
                  custom: true,
                },
                ...essayImprovementSuggestions,
              ];
              setEssayImprovementSuggestions(temp);
            }}
          >
            <AddIcon />
            <p>Add New Area of Improvement</p>
          </div>
        </>
      )}

      {possibilityEdit &&
        essayImprovementSuggestions.map((challenge, index) => (
          <div className={styles.curricular_section} key={challenge._id}>
            <div className={styles.top}>
              <button
                onClick={() => {
                  setRemoveId(challenge._id);
                  setOpen(true);
                }}
                className={styles.delete}
              >
                <Close /> Remove
              </button>
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={styles.remove_modal}>
                  <RemoveModalIcon />
                  <h2>Remove College</h2>
                  <p>Are you sure you want to remove this college?</p>
                  <div className={styles.remove_modal_buttons}>
                    <button
                      onClick={(e) => {
                        handleRemove(e, possibilityData._id);
                      }}
                      className={styles.remove}
                    >
                      yes
                    </button>
                    <button
                      onClick={() => {
                        setRemoveId('');
                        setOpen(false);
                      }}
                      className={styles.cancel}
                    >
                      no
                    </button>
                  </div>
                </Box>
              </Modal>
            </div>

            <div className={styles.input_tab}>
              <CustomTextField
                label="Title of Aspect"
                value={essayImprovementSuggestions[index].improvementArea}
                onChange={(e) => {
                  let temp = [...essayImprovementSuggestions];
                  temp[index].improvementArea = e.target.value;
                  setEssayImprovementSuggestions(temp);
                }}
              />

              <CustomTextField
                label="Description of aspect"
                multiline
                value={essayImprovementSuggestions[index].suggestion}
                onChange={(e) => {
                  let temp = [...essayImprovementSuggestions];
                  temp[index].suggestion = e.target.value;
                  setEssayImprovementSuggestions(temp);
                }}
              />
            </div>
          </div>
        ))}
    </>
  );
};

const EssaySection = ({
  item,
  essayEdit,
  collegeDetails,
  setCollegeDetails,
}) => {
  return (
    <>
      {!essayEdit ? (
        <>
          <h4>Justification</h4>
          <p>{item.collegeDetails.reason}</p>
          <h4>Examples of Colleges</h4>
          <div className={styles.college_list}>
            <ul>
              {item.collegeDetails.schools.map((school, index) => {
                return <li>{school}</li>;
              })}
            </ul>
          </div>
        </>
      ) : (
        <>
          <h4>Justification</h4>
          <CustomTextField
            label="Justification"
            multiline
            className={styles.justification_input}
            value={collegeDetails.reason}
            onChange={(e) => {
              setCollegeDetails((state) => ({
                ...state,
                reason: e.target.value,
              }));
            }}
          />
          <h4>Examples of Colleges</h4>
          <div className={styles.college_list_input}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[
                'Auburn University',
                'Cleveland State University',
                'Dartmouth College',
                'Drexel University',
                'Auburn University I',
                'Cleveland State University II',
              ]}
              defaultValue={item.collegeDetails.schools}
              onChange={(e, value) =>
                setCollegeDetails((state) => {
                  return {
                    ...state,
                    schools: value,
                  };
                })
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField {...params} label="List of College" multiline />
              )}
            />
          </div>
        </>
      )}
    </>
  );
};

const AspectSection = ({
  aspectData,
  gradeEdit,
  essayRemarksData,
  setEssayRemarksData,
}) => {
  function formatString(str) {
    // Split string by capital letters
    let temp = str.charAt(0).toUpperCase() + str.slice(1);
    let words = temp.match(/[A-Z][a-z]*/g);

    // Join the words with spaces
    let formattedString = words.join(' ');

    return formattedString;
  }

  return !gradeEdit
    ? aspectData &&
        aspectData.essayRemarks.map((aspect, index) => {
          return (
            <div className={styles.score_body} key={aspect._id}>
              <div className={styles.score_detail}>
                <div className={styles.score_detail_left}>
                  <h5>{`ASPECT ${index + 1}`}</h5>
                  <h2>{formatString(aspect.criteriaName)}</h2>
                </div>
                <div className={styles.score_detail_right}>
                  <h5>SCORE</h5>
                  <h3>{aspect.criteriaScore}/5</h3>
                </div>
              </div>
              <p>{aspect.comment}</p>
            </div>
          );
        })
    : aspectData.essayRemarks.map((item, index) => {
        return (
          <div className={styles.score_body} key={item._id}>
            <div className={styles.score_detail}>
              <div className={styles.score_detail_left}>
                <h5>{`ASPECT ${index + 1}`}</h5>

                <h2>{formatString(item.criteriaName)}</h2>
              </div>
              <div className={styles.score_detail_right}>
                <h5>SCORE</h5>
                <h3>
                  <input
                    className={styles.score_field}
                    value={essayRemarksData[index].criteriaScore}
                    onChange={(e) => {
                      let temp = [...essayRemarksData];
                      temp[index].criteriaScore = e.target.value;
                      setEssayRemarksData(temp);
                    }}
                  />
                  /5
                </h3>
              </div>
            </div>

            <LinearProgress
              variant="determinate"
              value={item.criteriaScore * 10}
              sx={{
                borderRadius: '5px',
                backgroundColor: '#E5E5E5',

                marginTop: '10px',
                marginBottom: '20px',
              }}
            />

            <TextField
              multiline
              className={styles.aspect_field}
              value={essayRemarksData[index].comment}
              onChange={(e) => {
                let temp = [...essayRemarksData];
                temp[index].comment = e.target.value;
                setEssayRemarksData(temp);
              }}
            />
          </div>
        );
      });
};

const Question = ({
  item,
  collegeDetails,
  setCollegeDetails,
  essayImprovementSuggestions,
  essayRemarks,
  setEssayImprovementSuggestions,
  setEssayRemarks,
  getEssayById,
  setLoading,
}) => {
  const [data, setData] = React.useState([]);
  const [expanded, setExpanded] = useState(false);
  const [gradeEdit, setGradeEdit] = useState(false);
  const [essayEdit, setEssayEdit] = useState(false);
  const [possibilityEdit, setPossibilityEdit] = useState(false);

  const [possibilityData, setPossibilityData] = React.useState([
    {
      name: 'Overcoming Challenges',
      justification:
        'Ensure your essay has a clear and well-organized structure, with each paragraph serving a distinct purpose and smooth transitions. This is actually a ton of dummy text that serves the purpose of a placeholder, nothing more.',
    },
  ]);

  const [gradeStatus, setGradeStatus] = React.useState(item.grade);

  const handleCollegeSave = async (id) => {
    setLoading(true);
    const data = collegeDetails;

    try {
      const response = await organizationAPI.updateCollegeDetails(id, data);
      console.log(response);
      await getEssayById();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setEssayEdit(!essayEdit);
  };

  const handleGradeSave = async (id) => {
    setLoading(true);
    const data = essayRemarks;
    try {
      const response = await organizationAPI.updateEssayRemarks(id, data);
      console.log(response);
      await getEssayById();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setGradeEdit(!gradeEdit);
  };

  const handlePossibilitySave = async (id) => {
    setLoading(true);
    const data = essayImprovementSuggestions;
    try {
      const response = await organizationAPI.updateEssayImprovementSuggestions(
        id,
        data
      );
      console.log(response);
      await getEssayById();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setPossibilityEdit(!possibilityEdit);
  };

  const handleGradeChange = async (gradeData) => {
    setLoading(true);
    const data = {
      grade: gradeData,
    };

    try {
      const response = await organizationAPI.updateGrade(item._id, data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }

    setGradeStatus(gradeData);

    await getEssayById();

    setLoading(false);
  };

  return (
    <div className={styles.sub_container_right}>
      <div className={styles.header_section}>
        <div className={styles.header_left}>
          <h2>{item.createdAt}</h2>
          <h6>{item.essayContent.title}</h6>
          {/* <p>Ellen Ripley</p> */}
        </div>
        <div className={styles.header_right}>
          <FormControl
            sx={{
              width: '100%',
            }}
          >
            <SelectGradeStatus
              label={'SELECT GRADE STATUS'}
              input={'GRADE STATUS'}
              value={gradeStatus}
              onChange={(e) => handleGradeChange(e.target.value)}
            />
          </FormControl>
        </div>
      </div>

      {data.length === 0 && (
        <p
          className={
            expanded ? styles.post_content_expanded : styles.post_content
          }
        >
          {item.essayContent.body}
        </p>
      )}
      <div className={styles.button_section}>
        <button
          className={styles.buttonExpand}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'EXPANDED' : 'EXPAND'}
        </button>
        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>

      <div className={styles.nfti_assessment}>
        <div className={styles.nfti_assessment_content}>
          <div className={styles.nfti_assessment_top}>
            <div className={styles.nfti_top_left}>
              <h5>OVERVIEW</h5>
              <h3>Essay Assessment</h3>
            </div>
            <div className={styles.nfti_top_right}>
              <div className={styles.score}>
                {essayEdit ? (
                  <>
                    <div className={styles.score_edit}>
                      <button
                        className={styles.save}
                        onClick={() => handleCollegeSave(item._id)}
                      >
                        <CheckIcon />
                        save
                      </button>
                      <button
                        className={styles.reject}
                        onClick={() => setEssayEdit(!essayEdit)}
                      >
                        <CloseIcon />
                        cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.score_view}>
                      <h5>TOTAL SCORE</h5>
                      <h3>
                        <ModeEditOutlineOutlinedIcon
                          onClick={() => setEssayEdit(!essayEdit)}
                        />
                        25/40
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <h6>Suitability: {item.collegeDetails.tier}</h6>
          <EssaySection
            item={item}
            essayEdit={essayEdit}
            collegeDetails={collegeDetails}
            setCollegeDetails={setCollegeDetails}
          />
        </div>
      </div>
      <div className={styles.nfti_assessment}>
        <div className={styles.nfti_assessment_top}>
          <div className={styles.nfti_top_left}>
            <h5>SCORING DETAILS</h5>
            <h3>Grade Breakdown and Remarks</h3>
          </div>
          <div className={styles.nfti_top_right}>
            <div className={styles.score}>
              {gradeEdit ? (
                <>
                  <div className={styles.score_edit}>
                    <button
                      className={styles.save}
                      onClick={() => handleGradeSave(item._id)}
                    >
                      <CheckIcon />
                      save
                    </button>
                    <button
                      className={styles.reject}
                      onClick={() => {
                        setEssayRemarks(item.essayRemarks);
                        setGradeEdit(!gradeEdit);
                      }}
                    >
                      <CloseIcon />
                      cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.score_view}>
                    <h5>TOTAL SCORE</h5>
                    <h3>
                      <ModeEditOutlineOutlinedIcon
                        onClick={() => setGradeEdit(!gradeEdit)}
                      />
                      25/40
                    </h3>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.border}></div>
        <AspectSection
          aspectData={item}
          gradeEdit={gradeEdit}
          essayRemarksData={essayRemarks}
          setEssayRemarksData={setEssayRemarks}
        />
      </div>
      <div className={styles.nfti_assessment}>
        <div className={styles.nfti_assessment_content}>
          <div className={styles.nfti_assessment_top}>
            <h3>Possible Areas of Improvement</h3>
            {possibilityEdit ? (
              <>
                <div className={styles.score_edit}>
                  <button
                    className={styles.save}
                    onClick={() => handlePossibilitySave(item._id)}
                  >
                    <CheckIcon />
                    save
                  </button>
                  <button
                    className={styles.reject}
                    onClick={() => setPossibilityEdit(!possibilityData)}
                  >
                    <CloseIcon />
                    cancel
                  </button>
                </div>
              </>
            ) : (
              <ModeEditOutlineOutlinedIcon
                className={styles.edit_icon}
                onClick={() => setPossibilityEdit(!possibilityEdit)}
              />
            )}
          </div>
          <PossibilitySection
            possibilityEdit={possibilityEdit}
            possibilityData={item}
            essayImprovementSuggestions={essayImprovementSuggestions}
            setEssayImprovementSuggestions={setEssayImprovementSuggestions}
            setLoading={setLoading}
            getEssayById={getEssayById}
          />
        </div>
      </div>
    </div>
  );
};

const SubmittedAssessment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const admissionId = location.pathname.split('/')[4];

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [assessment, setAssessment] = useState([]);

  const [collegeDetails, setCollegeDetails] = useState([]);

  const [essayImprovementSuggestions, setEssayImprovementSuggestions] =
    useState([]);

  const [essayRemarks, setEssayRemarks] = useState([]);

  const handleReturn = (e) => {
    e.preventDefault();
    navigate('/coach/admission/dashboard');
  };
  const handleSelectedQuestion = (index) => {
    let temp = [...assessment];
    temp.map((item, i) => {
      if (i === index) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    });
    setEssayImprovementSuggestions([
      ...temp[index].essayImprovementSuggestions,
    ]);
    setCollegeDetails(temp[index].collegeDetails);
    setEssayRemarks([...temp[index].essayRemarks]);
    setAssessment(temp);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getEssayById = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getEssayById(admissionId);
      const temp = response.data.map((item, index) => {
        return {
          ...item,
          createdAt: moment(item.createdAt).format('MMM Do, YYYY'),

          isSelected: index === 0 ? true : false,
        };
      });
      setCollegeDetails(temp[0].collegeDetails);
      setEssayImprovementSuggestions([...temp[0].essayImprovementSuggestions]);
      setEssayRemarks([...temp[0].essayRemarks]);

      setAssessment(temp);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getEssayById();
  }, []);

  return (
    <>
      {loading && <LoadingOverlay />}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Admission Attempt Spring 2024</h1>

          <div className={styles.header_buttons}>
            <button onClick={handleReturn} className={styles.return}>
              <ArrowBackIosIcon />
              <span>Return to Dashboard</span>
            </button>
          </div>
        </div>
        <div className={styles.border}></div>

        <div className={styles.all}>
          <div className={styles.sub_container_left}>
            {assessment.length === 0 && (
              <div className={styles.assessment_section}>
                <p>You have not posted any content for assessment yet.</p>
              </div>
            )}
            {assessment.length > 0 &&
              assessment.map((item, index) => {
                if (item.isSelected) {
                  return (
                    <div
                      className={styles.sub_container_left_bottom}
                      onClick={() => handleSelectedQuestion(index)}
                    >
                      <p>{item.createdAt}</p>
                      <h5>
                        {item.status === 'done' && (
                          <DoneIcon fill={'#2AA87E'} />
                        )}
                        {item.status === 'review' && (
                          <ProgressIcon fill={'#0AA6E0'} />
                        )}
                        {item.status === 'progress' && <PendingIcon />}
                        {item.essayContent.title.length > 60
                          ? item.essayContent.title.substring(0, 60) + '...'
                          : item.essayContent.title}
                      </h5>
                      <h4>{item.author}</h4>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={styles.sub_container_left_top}
                      onClick={() => handleSelectedQuestion(index)}
                    >
                      <p>{item.createdAt}</p>

                      <h5>
                        {item.status === 'done' && (
                          <DoneIcon fill={'#2AA87E'} />
                        )}
                        {item.status === 'review' && (
                          <ProgressIcon fill={'#0AA6E0'} />
                        )}
                        {item.status === 'progress' && <PendingIcon />}
                        {item.essayContent.title.length > 60
                          ? item.essayContent.title.substring(0, 60) + '...'
                          : item.essayContent.title}
                      </h5>
                      <h4>{item.author}</h4>
                    </div>
                  );
                }
              })}
          </div>
          <div className={styles.essay_section}>
            <div className="sub_container_right_section">
              {assessment.map((item, index) => {
                if (item.isSelected) {
                  return (
                    <Question
                      setLoading={setLoading}
                      item={item}
                      isSelected={item.isSelected}
                      collegeDetails={collegeDetails}
                      setCollegeDetails={setCollegeDetails}
                      essayImprovementSuggestions={essayImprovementSuggestions}
                      setEssayImprovementSuggestions={
                        setEssayImprovementSuggestions
                      }
                      essayRemarks={essayRemarks}
                      setEssayRemarks={setEssayRemarks}
                      getEssayById={getEssayById}
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmittedAssessment;
