import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { FormControl, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
import { moodColors } from 'utils/status-color';

export const TeamIndividulaCallHistory = ({ data, personaID, behaviors }) => {
  const { selectPersona, selectedPersona, selectPersonaBehavior } =
    usePersonaStore();
  const navigate = useNavigate();
  const maleImage = '/male.jpg';
  const femaleImage = '/female.jpg';

  const [selectedBehaviors, setSelectedBehaviors] = useState({
    mood: { id: '', name: '' },
    tone: { id: '', name: '' },
    difficulty: { id: '', name: '' },
  });

  useEffect(() => {
    if (behaviors && behaviors.mood && behaviors.mood.length > 0) {
      setSelectedBehaviors({
        mood: behaviors.mood[0],
        tone: behaviors.tone[0],
        difficulty: behaviors.difficulty[0],
      });
    }
  }, [behaviors]);
  useEffect(() => {
    selectPersonaBehavior(
      selectedBehaviors.mood,
      selectedBehaviors.tone,
      selectedBehaviors.difficulty
    );
  }, [selectedBehaviors]);

  const imageUrl =
    data.avatar && data.avatar !== 'invalid'
      ? data.avatar
      : data.gender === 'male'
      ? maleImage
      : femaleImage;

  const handleNavigation = () => {
    navigate(`/airoleplaycall/ongoingcall/${data._id}`);
  };

  const handleBehaviorChange = (category, value) => {
    setSelectedBehaviors((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  const handlePersonaSelect = () => {
    selectPersona(personaID);
  };

  const handlePersonaAndBehaviorSelect = () => {
    selectPersona(data._id);

    handleNavigation();
  };

  return (
    <Box
      sx={{
        border: `1px solid ${
          selectedPersona?._id === personaID ? '#00BF8C' : '#dae8e7'
        }`,
        borderRadius: '8px',
        width: '94%',
        height: '100%',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={handlePersonaSelect}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: '10px',
            padding: '5px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                overflow: 'hidden',
              }}
            >
              <img
                src={imageUrl}
                alt={data?.name || 'Profile Image'}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography
                sx={{
                  color: '#243030',
                  fontFamily: 'Lexend',
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {data?.name || 'Anonymous'}
              </Typography>
              <Typography
                sx={{
                  color: '#5b706f',
                  fontFamily: 'Lexend',
                  fontSize: '14px',
                  fontWeight: 300,
                }}
              >
                {data?.role ? `${data.role}` : ''}{' '}
                {data?.company ? `@ ${data.company}` : ''}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            {/* <Typography
              color={
                moodColors[String(selectedBehaviors.mood.name).toLowerCase()] ||
                moodColors.default
              }
              fontSize={14}
              fontWeight={500}
              fontFamily="Lexend"
              noWrap
              sx={{
                width: 'fit-content',
                padding: '1px 8px',
                border: `1px solid ${
                  moodColors[
                    String(selectedBehaviors.mood.name).toLowerCase()
                  ] || moodColors.default
                }`,
                borderRadius: '20px',
              }}
            >
              {selectedBehaviors.mood.name}
            </Typography> */}
            <IconButton
              sx={{
                width: '32px',
                height: '32px',
                border: '1px solid #00706c',
                borderRadius: '50%',
                color: '#00706c',
                '&:hover': {
                  backgroundColor: '#00706c',
                  color: '#fff',
                },
              }}
              onClick={handlePersonaAndBehaviorSelect}
            >
              <LocalPhoneIcon fontSize="small" />{' '}
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            width: '100%',
          }}
        >
          {/* Mood Dropdown */}
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: '#92a7a5',
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Lexend',
                textWrap: 'nowrap',
              }}
            >
              MOOD
            </Typography>
            {behaviors && behaviors.mood && (
              <FormControl sx={{ minWidth: 130 }}>
                <Select
                  value={selectedBehaviors.mood.id}
                  onChange={(e) => {
                    const selectedMood = behaviors.mood.find(
                      (mood) => mood.id === e.target.value
                    );
                    handleBehaviorChange('mood', selectedMood);
                  }}
                  sx={{ height: '40px' }}
                >
                  {behaviors.mood.map((mood) => (
                    <MenuItem key={mood.id} value={mood.id}>
                      {mood.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          {/* Tone Dropdown */}
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: '#92a7a5',
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Lexend',
                textWrap: 'nowrap',
              }}
            >
              TONE
            </Typography>
            {behaviors && behaviors.tone && (
              <FormControl sx={{ minWidth: 130 }}>
                <Select
                  value={selectedBehaviors.tone.id}
                  onChange={(e) => {
                    const selectedTone = behaviors.tone.find(
                      (tone) => tone.id === e.target.value
                    );
                    handleBehaviorChange('tone', selectedTone);
                  }}
                  sx={{ height: '40px' }}
                >
                  {behaviors.tone.map((tone) => (
                    <MenuItem key={tone.id} value={tone.id}>
                      {tone.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          {/* Difficulty Dropdown */}
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: '#92a7a5',
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Lexend',
                textWrap: 'nowrap',
              }}
            >
              DIFFICULTY
            </Typography>
            {behaviors && behaviors.difficulty && (
              <FormControl sx={{ minWidth: 130 }}>
                <Select
                  value={selectedBehaviors.difficulty.id}
                  onChange={(e) => {
                    const selectedDifficulty = behaviors.difficulty.find(
                      (difficulty) => difficulty.id === e.target.value
                    );
                    handleBehaviorChange('difficulty', selectedDifficulty);
                  }}
                  sx={{ height: '40px' }}
                >
                  {behaviors.difficulty.map((difficulty) => (
                    <MenuItem key={difficulty.id} value={difficulty.id}>
                      {difficulty.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamIndividulaCallHistory;
