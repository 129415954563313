import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import ScoreboardOutlinedIcon from '@mui/icons-material/ScoreboardOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import WavingHandOutlinedIcon from '@mui/icons-material/WavingHandOutlined';
import LoadingOverlay from 'components/LoadingOverlay';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Person from '../../../images/Ellipse.png';
import Person2 from '../../../images/person2.png';
import Objection from './Objection';
import Questions from './Questions';
import Scoreboard from './Scoreboard';
import styles from './styles/Transcript.module.css';
import Summary from './Summary';

const DocumentDashboard = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const navigate = useNavigate();

  const [active, setActive] = useState([
    { name: 'Summary', active: true, status: 'pending' },
    { name: 'Scorecard', active: false, status: 'pending' },
    { name: 'Questions', active: false, status: 'pending' },
    { name: 'Objection', active: false, status: 'pending' },
  ]);

  const handleReturn = () => {
    navigate('/student/coversationalai/dashboard');
  };

  return (
    <>
      {loading && <LoadingOverlay />}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Conversational AI</h1>
          <button className={styles.return} onClick={handleReturn}>
            <ArrowBackIosNewOutlinedIcon />
            Return to Conversational AI
          </button>
        </div>

        <div className={styles.border}></div>

        <div className={styles.coach_roaster}>
          <div className={styles.coach_roaster_left}>
            <div className={styles.analyzer_section}>
              <div className={styles.course_list}>
                <ul>
                  {active.map((item, index) => (
                    <li
                      key={index}
                      className={item.active ? styles.active : ''}
                      onClick={() => {
                        const newActive = active.map((item, i) => {
                          if (i === index) {
                            return { ...item, active: true };
                          } else {
                            return { ...item, active: false };
                          }
                        });
                        setActive(newActive);
                      }}
                    >
                      {item.name === 'Summary' && (
                        <SummarizeOutlinedIcon
                          className={item.active && styles.icon_active}
                        />
                      )}
                      {item.name === 'Scorecard' && (
                        <ScoreboardOutlinedIcon
                          className={item.active && styles.icon_active}
                        />
                      )}
                      {item.name === 'Questions' && (
                        <QuizOutlinedIcon
                          className={item.active && styles.icon_active}
                        />
                      )}
                      {item.name === 'Objection' && (
                        <WavingHandOutlinedIcon
                          className={item.active && styles.icon_active}
                        />
                      )}
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.container}>
                {active.map((item) => {
                  if (item.active) {
                    if (item.name === 'Summary') {
                      return <Summary />;
                    } else if (item.name === 'Scorecard') {
                      return <Scoreboard />;
                    } else if (item.name === 'Questions') {
                      return <Questions />;
                    } else if (item.name === 'Objection') {
                      return <Objection />;
                    }
                  }
                })}
              </div>
            </div>
          </div>

          <div className={styles.coach_roaster_right}>
            <div className={styles.transcript_head}>
              <h6>Conversation Transcript</h6>
              <audio
                controls
                src="https://samplelib.com/lib/preview/mp3/sample-12s.mp3"
              ></audio>
            </div>
            <div className={styles.transcript_body}>
              <div className={styles.chat_header}>
                <p>
                  Click on specific messages to quickly skip to that part of the
                  audio.
                </p>
              </div>

              <div className={styles.transcript_chat}>
                <div className={styles.chat_box}>
                  <div className={styles.chat_box_left}>
                    <img src={Person} alt="" />
                    <div className={styles.chat_box_text}>
                      <span>0:00</span>
                      <p>Hello</p>
                    </div>
                  </div>
                  <div className={styles.chat_box_right}>
                    <div className={styles.chat_box_text_reply}>
                      <span>0:15</span>
                      <p>
                        Hello, Mr. Cuban. It is an honor to speak to you, sir.
                      </p>
                    </div>
                    <img src={Person2} alt="" />
                  </div>
                  <div className={styles.chat_box_left}>
                    <img src={Person} alt="" />
                    <div className={styles.chat_box_text}>
                      <span>0:00</span>
                      <p>Hello</p>
                    </div>
                  </div>
                  <div className={styles.chat_box_right}>
                    <div className={styles.chat_box_text_reply}>
                      <span>0:15</span>
                      <p>
                        Hello, Mr. Cuban. It is an honor to speak to you, sir.
                      </p>
                    </div>

                    <img src={Person2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentDashboard;
