import { Box, Stack, Typography } from '@mui/material';

import { Tab } from 'components/Tab';
import { TabItems } from './common/const';

export const ConvoDashboard = () => {
  const Header = (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Dashboard</Typography>
    </Stack>
  );

  return (
    <Stack direction={'column'} gap={5}>
      {Header}
      <Box sx={{ width: '100%' }}>
        <Tab tabItems={TabItems} />
      </Box>
    </Stack>
  );
};
