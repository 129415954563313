import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import usePersonaStore from 'store/personaStore';
import { difficultyColors, moodColors, toneColors } from 'utils/status-color';
import styles from './styles/Style.module.css';

const SelectedPersona = () => {
  const { selectedPersona, personaBehavior } = usePersonaStore();
  const maleImage = '/male.jpg';
  const femaleImage = '/female.jpg';

  if (selectedPersona) {
    const imageUrl =
      selectedPersona.avatar && selectedPersona.avatar !== 'invalid'
        ? selectedPersona.avatar
        : selectedPersona.gender === 'male'
        ? maleImage
        : femaleImage;

    return (
      <div className={styles.persona_container}>
        <h1>Selected Buyer Persona</h1>
        <div className={styles.persona_information}>
          <div className={styles.persona_image}>
            <img src={imageUrl} alt={imageUrl} />
          </div>
          <div className={styles.info_one}>
            <h3>{selectedPersona.name}</h3>
            <p>{selectedPersona.role}</p>
            {selectedPersona.company && <p>@ {selectedPersona.company}</p>}

            {personaBehavior && (
              <div className={styles.behavior}>
                <span
                  className={styles.status}
                  style={{
                    color:
                      moodColors[
                        String(personaBehavior.mood.name).toLowerCase()
                      ] || moodColors.default,
                    border: `1px solid ${
                      moodColors[
                        String(personaBehavior.mood.name).toLowerCase()
                      ] || moodColors.default
                    }`,
                    textTransform: 'capitalize',
                  }}
                >
                  {personaBehavior.mood.name}
                </span>
                <span
                  className={styles.status}
                  style={{
                    color:
                      toneColors[
                        String(personaBehavior.tone.name).toLowerCase()
                      ] || toneColors.default,
                    border: `1px solid ${
                      toneColors[
                        String(personaBehavior.tone.name).toLowerCase()
                      ] || toneColors.default
                    }`,
                    textTransform: 'capitalize',
                  }}
                >
                  {personaBehavior.tone.name}
                </span>
                <span
                  className={styles.status}
                  style={{
                    color:
                      difficultyColors[
                        String(personaBehavior.difficulty.name).toLowerCase()
                      ] || difficultyColors.default,
                    border: `1px solid ${
                      difficultyColors[
                        String(personaBehavior.difficulty.name).toLowerCase()
                      ] || difficultyColors.default
                    }`,
                    textTransform: 'capitalize',
                  }}
                >
                  {personaBehavior.difficulty.name}
                </span>
              </div>
            )}
          </div>
          <div className={styles.info_two}>
            <h3>Persona Brief</h3>
            <p>{selectedPersona.description}</p>
          </div>
        </div>
        <a
          className={styles.start_call}
          href={`/airoleplaycall/ongoingcall/${selectedPersona._id}`}
        >
          <LocalPhoneIcon />
          <span>Start Call</span>
        </a>
      </div>
    );
  } else {
    return (
      <div className={styles.skeleton_container}>
        <h1 className={styles.skeleton_title} />
        <div className={styles.skeleton_information}>
          <div className={styles.skeleton_image}>
            <div className={`${styles.skeleton_circle}`} />
          </div>
          <div className={styles.skeleton_info_one}>
            <h3 className={styles.skeleton_text} />
            <p className={styles.skeleton_text} />
            <p className={styles.skeleton_text} />
            <span className={styles.skeleton_status}>
              <div className={styles.skeleton_text} />
            </span>
          </div>
          <div className={styles.skeleton_info_two}>
            <h3 className={styles.skeleton_brief_title} />
            <p className={styles.skeleton_brief_description} />
          </div>
        </div>
        <a className={styles.skeleton_start_call} href="#">
          <LocalPhoneIcon />
          <span className={styles.skeleton_text} />
        </a>
      </div>
    );
  }
};

export default SelectedPersona;
