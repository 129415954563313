import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Menu, MenuOpen } from '@mui/icons-material';
import apis from 'api/api';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { useSidebarItems } from './items';

const SidebarItem = ({ title, path, icon, onClick, isActive }) => {
  return (
    <ListItemButton
      component={Link}
      to={path}
      selected={isActive}
      sx={{
        color: isActive ? 'success.main' : 'background.paper',
        backgroundColor: isActive ? '#1F1F1F' : 'transparent',
        '&:hover': {
          backgroundColor: '#2C2C2C',
        },
        padding: '12px 20px',
      }}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
    >
      <ListItemIcon
        sx={{ color: isActive ? 'success.main' : 'background.paper' }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

export const Sidebar = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const { sidebarTopItems, sidebarBottomItems } = useSidebarItems();
  const [activePath, setActivePath] = useState(location.pathname);
  const navigate = useNavigate();

  const {
    data,
    call: getOrgMeta,
    isLoading: orgMetaLoading,
  } = useApi({
    fetcher: apis.getOrganizationMetaByRole,
  });

  
  const accessibleMenus = data?.role?.widgtes ?? [];

  const sidebarUrls = [
    ...sidebarTopItems.map((item) => item.path),
    ...sidebarBottomItems.map((item) => item.path),
  ];

  useEffect(() => {
    if (sidebarUrls.includes(location.pathname)) {
      setActivePath(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    const organizationName = localStorage.getItem('organizationCode');
    getOrgMeta(organizationName);
  }, []);

  return (
    <Drawer
      className={`sidebarDrawer ${collapsed ? 'sidebarDrawer-collapsed' : ''}`}
      variant="permanent"
      sx={{
        width: collapsed ? 70 : 220,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: collapsed ? 70 : 220,
          backgroundColor: '#121212',
          color: 'background.paper',
          boxSizing: 'border-box',
          transition: 'width 0.3s',
          overflowX: 'hidden',
        },
      }}
    >
      <Box sx={{ padding: '10px' }}>
        <IconButton onClick={() => setCollapsed((prev) => !prev)}>
          {collapsed ? <Menu color="success" /> : <MenuOpen color="success" />}
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '10px 10px',
          gap: 3,
          textOverflow: 'unset',
        }}
      >
        {orgMetaLoading || !data?.navbarLogo ? (
          <Skeleton
            variant="rectangular"
            sx={{ backgroundColor: 'GrayText', borderRadius: 0.5 }}
          >
            <Avatar variant="rounded" />
          </Skeleton>
        ) : (
          <Avatar
            src={data?.navbarLogo}
            alt="Logo"
            variant="rounded"
            sx={{
              width: 40,
              '& img': {
                objectFit: 'contain',
              },
            }}
          />
        )}
        <Typography variant="h6" color="primary" noWrap>
          TIER ONE
        </Typography>
      </Box>
      <Stack
        height={'100vh'}
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <List>
          {sidebarTopItems
            .filter((item) => {
              if (item.forWidget.includes('*')) return true;
              return item.forWidget.some((widget) =>
                accessibleMenus.includes(widget)
              );
            })
            .map((item) => (
              <SidebarItem
                key={item.id}
                title={item.title}
                path={item.path}
                icon={item.icon}
                isActive={activePath === item.path}
              />
            ))}
        </List>

        <List>
          {sidebarBottomItems
            .filter((item) => {
              if (item.forWidget.includes('*')) return true;
              return item.forWidget.some((widget) =>
                accessibleMenus.includes(widget)
              );
            })
            .map((item) => (
              <SidebarItem
                key={item.id}
                title={item.title}
                path={item.path}
                icon={item.icon}
                isActive={activePath === item.path}
                onClick={(e) => {
                  if (item.title === 'Logout') {
                    e.preventDefault();
                    navigate('/logout');
                  }
                }}
              />
            ))}
        </List>
      </Stack>
    </Drawer>
  );
};
