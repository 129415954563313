export const createSortedSelectOptions = (
  items = [],
  valueKey = 'id',
  labelKeys = ['name']
) => {
  const keys = Array.isArray(labelKeys) ? labelKeys : [labelKeys];

  const options = items.map((item) => {
    const label = keys
      .map((key) => item[key])
      .join(' ')
      .trim();
    return {
      value: item[valueKey],
      label,
    };
  });

  return options.sort((a, b) => a.label.localeCompare(b.label));
};
