import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styles from './styles/Assessment.module.css';

import { useLocation, useNavigate } from 'react-router-dom';

import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SendIcon from '@mui/icons-material/Send';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  LinearProgress,
  Modal,
  TextField,
} from '@mui/material';
import organizationAPI from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { SelectGradeStatus } from 'components/SelectField';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  DoneIcon,
  PendingIcon,
  ProgressIcon,
  RemoveModalIcon,
} from 'svg/CustomSVGIcon';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const AddEssay = ({
  navigate,
  admissionId,
  setLoading,
  getEssayById,
  setOpen,
}) => {
  const [file, setFile] = useState(null);
  const [assessment, setAssessment] = useState([]);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  function formatText(texts) {
    let textFinal = '';
    for (let i = 0; i < texts.items.length; i++) {
      textFinal += texts.items[i].str;
    }

    setBody(textFinal);
  }

  const handleFileLoad = async (e) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const fileType = ['application/pdf'];
    const file = e.target.files[0];

    if (file && fileType.includes(file.type)) {
      setFile(file);
    }
  };

  const handleSubmission = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      templateId: admissionId,
      essayContent: {
        title,
        body,
      },
    };

    const response = await organizationAPI.submitEssay(data);
    getEssayById();
  };
  useEffect(() => {
    if (title && body) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [title, body]);
  return (
    <>
      {file && (
        <Document
          file={file}
          renderMode="none"
          onLoadSuccess={(document) => {
            console.log(document);
          }}
        >
          <Page
            className={styles.page}
            pageNumber={1}
            renderAnnotationLayer={false}
            renderTextLayer={true}
            onGetTextSuccess={(text) => formatText(text)}
          />
        </Document>
      )}
      <div className={styles.essay_heading}>
        <p>All fields are required.</p>
      </div>

      <TextField
        id="input-with-icon-textfield"
        label="ESSAY TITLE"
        placeholder="Title of your essay (leave blank to parse automatically from submitted content or document)"
        variant="outlined"
        multiline
        className={styles.description}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        id="input-with-icon-textfield"
        label="ESSAY BODY"
        placeholder="Type your essay into this text field. You can also click the button below to upload it as a document, or drag a document into this field to upload it."
        variant="outlined"
        multiline
        className={styles.description}
        inputProps={{
          style: {
            minHeight: '192px',
            maxHeight: '575px',
          },
        }}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />

      <div className={styles.essay_buttons}>
        <input
          type="file"
          id="file"
          style={{ display: 'none' }}
          onChange={handleFileLoad}
        />
        <label htmlFor="file" className={styles.upload}>
          <UploadIcon />
          <span>Upload Document</span>
        </label>

        <button
          onClick={handleSubmission}
          className={styles.submit}
          disabled={disableButton}
        >
          <SendIcon />
          <span>Submit for Assessment</span>
        </button>

        <button onClick={() => setOpen(false)} className={styles.close}>
          <span>Close</span>
        </button>
      </div>
    </>
  );
};

const PossibilitySection = ({ possibilityEdit, possibilityData }) => {
  const [newChallenge, setNewChallenge] = useState([]);
  const [open, setOpen] = React.useState(false);
  function formatString(str) {
    // Split string by capital letters
    let temp = str.charAt(0).toUpperCase() + str.slice(1);
    let words = temp.match(/[A-Z][a-z]*/g);

    // Join the words with spaces
    let formattedString = words.join(' ');

    return formattedString;
  }
  const handleRemove = (id) => {
    setNewChallenge(newChallenge.filter((challenge) => challenge !== id));
    setOpen(false);
  };
  return (
    <>
      {!possibilityEdit ? (
        possibilityData.essayImprovementSuggestions.map((item) => {
          return (
            <div className={styles.score_body}>
              <div className={styles.score_detail}>
                <div className={styles.score_detail_left}>
                  <h2>{formatString(item.improvementArea)}</h2>
                </div>
              </div>
              <p>{item.suggestion}</p>
            </div>
          );
        })
      ) : (
        <>
          <div
            className={styles.add}
            onClick={() => {
              console.log(crypto.randomUUID());
              setNewChallenge([...newChallenge, crypto.randomUUID()]);
            }}
          >
            <AddIcon />
            <p>Add New Area of Improvement</p>
          </div>
          {newChallenge &&
            newChallenge.map((challenge, index) => (
              <div className={styles.curricular_section} key={challenge}>
                <div className={styles.top}>
                  <button
                    onClick={() => setOpen(true)}
                    className={styles.delete}
                  >
                    <Close /> Remove
                  </button>
                  <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className={styles.remove_modal}>
                      <RemoveModalIcon />
                      <h2>Remove College</h2>
                      <p>Are you sure you want to remove this college?</p>
                      <div className={styles.remove_modal_buttons}>
                        <button
                          onClick={() => {
                            handleRemove(challenge);
                          }}
                          className={styles.remove}
                        >
                          yes
                        </button>
                        <button
                          onClick={() => setOpen(false)}
                          className={styles.cancel}
                        >
                          no
                        </button>
                      </div>
                    </Box>
                  </Modal>
                </div>
                <div className={styles.input_tab}>
                  <TextField label="Title of Aspect" multiline />
                  <TextField label="Description of aspect" multiline />
                </div>
              </div>
            ))}
        </>
      )}
    </>
  );
};

const EssaySection = ({ item, essayEdit }) => {
  const [receivers, setReceivers] = useState([]);
  return (
    <>
      {!essayEdit ? (
        <>
          <h4>Justification</h4>
          <p>{item.collegeDetails.reason}</p>
          <h4>Examples of Colleges</h4>
          <div className={styles.college_list}>
            <ul>
              {item.collegeDetails.schools.map((school, index) => {
                return <li>{school}</li>;
              })}
            </ul>
          </div>
        </>
      ) : (
        <>
          <h4>Justification</h4>
          <TextField
            multiline
            className={styles.justification_input}
            defaultValue={item.collegeDetails.reason}
          />
          <h4>Examples of Colleges</h4>
          <div className={styles.college_list_input}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[
                'Auburn University',
                'Cleveland State University',
                'Dartmouth College',
                'Drexel University',
                'Auburn University I',
                'Cleveland State University II',
              ]}
              defaultValue={item.collegeDetails.schools}
              onChange={(e, value) => setReceivers((state) => value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField {...params} label="List of College" multiline />
              )}
            />
          </div>
        </>
      )}
    </>
  );
};

const AspectSection = ({ aspectData, gradeEdit }) => {
  function formatString(str) {
    // Split string by capital letters
    let temp = str.charAt(0).toUpperCase() + str.slice(1);
    let words = temp.match(/[A-Z][a-z]*/g);

    // Join the words with spaces
    let formattedString = words.join(' ');

    return formattedString;
  }

  return !gradeEdit
    ? aspectData &&
        aspectData.essayRemarks.map((aspect, index) => {
          return (
            <div className={styles.score_body}>
              <div className={styles.score_detail}>
                <div className={styles.score_detail_left}>
                  <h5>{`ASPECT ${index + 1}`}</h5>
                  <h2>{formatString(aspect.criteriaName)}</h2>
                </div>
                <div className={styles.score_detail_right}>
                  <h5>SCORE</h5>
                  <h3>{aspect.criteriaScore}/5</h3>
                </div>
              </div>
              <p>{aspect.comment}</p>
            </div>
          );
        })
    : aspectData.essayRemarks.map((item, index) => {
        return (
          <div className={styles.score_body}>
            <div className={styles.score_detail}>
              <div className={styles.score_detail_left}>
                <h5>{`ASPECT ${index + 1}`}</h5>

                <h2>{formatString(item.criteriaName)}</h2>
              </div>
              <div className={styles.score_detail_right}>
                <h5>SCORE</h5>
                <h3>
                  <input
                    className={styles.score_field}
                    defaultValue={item.criteriaScore}
                  />
                  /5
                </h3>
              </div>
            </div>

            <LinearProgress
              variant="determinate"
              value={item.criteriaScore * 10}
              sx={{
                borderRadius: '5px',
                backgroundColor: '#E5E5E5',

                marginTop: '10px',
                marginBottom: '20px',
              }}
            />

            <TextField
              multiline
              className={styles.aspect_field}
              defaultValue={item.comment}
            />
          </div>
        );
      });
};

const Question = ({ item, setLoading }) => {
  const [data, setData] = React.useState([]);
  const [reply, setReply] = React.useState('');
  const [expanded, setExpanded] = useState(false);
  const [gradeEdit, setGradeEdit] = useState(false);
  const [essayEdit, setEssayEdit] = useState(false);
  const [possibilityEdit, setPossibilityEdit] = useState(false);

  const [possibilityData, setPossibilityData] = React.useState([
    {
      name: 'Overcoming Challenges',
      justification:
        'Ensure your essay has a clear and well-organized structure, with each paragraph serving a distinct purpose and smooth transitions. This is actually a ton of dummy text that serves the purpose of a placeholder, nothing more.',
    },
  ]);

  const [generate, setGenerate] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [star, setStar] = React.useState(false);
  const [gradeStatus, setGradeStatus] = React.useState(item.grade);
  const handleReply = (e) => {
    e.preventDefault();
    setData([...data, { name: 'Mr. Kene Turner', reply: reply }]);
    setReply('');
  };

  const handleRegenerate = (e) => {
    e.preventDefault();
    setReply(
      'Your answer is being generated. Please waitIn general, yes. Businesses pays taxes for government services. These include public trans- portation and highways, social programs, national defense, and assistance for business itself. Business payroll taxes fund workers’ insurance and retirement; unemployment taxes aid workers who have lost their jobs; sales taxes are collected on products and services sold and sent to state governments. Businesses pay income tax on their profits and property tax on buildings and inventory. Businesses can reduce their tax payments by taking deductions and using tax credits..'
    );
    setGenerate(true);
  };

  return (
    <div className={styles.sub_container_right}>
      <div className={styles.header_section}>
        <div className={styles.header_left}>
          <h2>{item.createdAt}</h2>
          <h6>{item.essayContent.title}</h6>
          {/* <p>Ellen Ripley</p> */}
        </div>
        <div className={styles.header_right}>
          <FormControl
            sx={{
              width: '100%',
            }}
          >
            <SelectGradeStatus
              label={'SELECT GRADE STATUS'}
              input={'GRADE STATUS'}
              value={gradeStatus}
              setValue={setGradeStatus}
              disabled={true}
            />
          </FormControl>
        </div>
      </div>

      {data.length === 0 && (
        <p
          className={
            expanded ? styles.post_content_expanded : styles.post_content
          }
        >
          {item.essayContent.body}
        </p>
      )}
      <div className={styles.button_section}>
        <button
          className={styles.buttonExpand}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'EXPANDED' : 'EXPAND'}
        </button>
        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>

      <div className={styles.nfti_assessment}>
        <div className={styles.nfti_assessment_content}>
          <div className={styles.nfti_assessment_top}>
            <div className={styles.nfti_top_left}>
              <h5>OVERVIEW</h5>
              <h3>Essay Assessment</h3>
            </div>
            <div className={styles.nfti_top_right}>
              <div className={styles.score}>
                {essayEdit ? (
                  <>
                    <div className={styles.score_edit}>
                      <button
                        className={styles.save}
                        onClick={() => setEssayEdit(!essayEdit)}
                      >
                        <CheckIcon />
                        save
                      </button>
                      <button
                        className={styles.reject}
                        onClick={() => setEssayEdit(!essayEdit)}
                      >
                        <CloseIcon />
                        cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.score_view}>
                      <h5>TOTAL SCORE</h5>
                      <h3>
                        {/* <ModeEditOutlineOutlinedIcon
                          onClick={() => setEssayEdit(!essayEdit)}
                        /> */}
                        25/40
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <h6>Suitability: {item.collegeDetails.tier}</h6>
          <EssaySection item={item} essayEdit={essayEdit} />
        </div>
      </div>
      <div className={styles.nfti_assessment}>
        <div className={styles.nfti_assessment_top}>
          <div className={styles.nfti_top_left}>
            <h5>SCORING DETAILS</h5>
            <h3>Grade Breakdown and Remarks</h3>
          </div>
          <div className={styles.nfti_top_right}>
            <div className={styles.score}>
              {gradeEdit ? (
                <>
                  <div className={styles.score_edit}>
                    <button
                      className={styles.save}
                      onClick={() => setGradeEdit(!gradeEdit)}
                    >
                      <CheckIcon />
                      save
                    </button>
                    <button
                      className={styles.reject}
                      onClick={() => setGradeEdit(!gradeEdit)}
                    >
                      <CloseIcon />
                      cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.score_view}>
                    <h5>TOTAL SCORE</h5>
                    <h3>
                      {/* <ModeEditOutlineOutlinedIcon
                        onClick={() => setGradeEdit(!gradeEdit)}
                      /> */}
                      25/40
                    </h3>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.border}></div>
        <AspectSection aspectData={item} gradeEdit={gradeEdit} />
      </div>
      <div className={styles.nfti_assessment}>
        <div className={styles.nfti_assessment_content}>
          <div className={styles.nfti_assessment_top}>
            <h3>Possible Areas of Improvement</h3>
            {/* {possibilityEdit ? (
              <>
                <div className={styles.score_edit}>
                  <button
                    className={styles.save}
                    onClick={() => setPossibilityEdit(!possibilityData)}
                  >
                    <CheckIcon />
                    save
                  </button>
                  <button
                    className={styles.reject}
                    onClick={() => setPossibilityEdit(!possibilityData)}
                  >
                    <CloseIcon />
                    cancel
                  </button>
                </div>
              </>
            ) : (
              <ModeEditOutlineOutlinedIcon
                className={styles.edit_icon}
                onClick={() => setPossibilityEdit(!possibilityEdit)}
              />
            )} */}
          </div>
          <PossibilitySection
            possibilityEdit={possibilityEdit}
            possibilityData={item}
          />
        </div>
      </div>
    </div>
  );
};

const SubmittedAssessment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const admissionId = location.pathname.split('/')[4];

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [assessment, setAssessment] = useState([]);

  const handleReturn = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };
  const handleSelectedQuestion = (index) => {
    let temp = [...assessment];
    temp.map((item, i) => {
      if (i === index) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    });
    setAssessment(temp);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getEssayById = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getEssayById(admissionId);
      const reversedData = response.data.reverse();
      const temp = reversedData.map((item, index) => {
        return {
          ...item,
          createdAt: moment(item.createdAt).format('MMM Do, YYYY'),

          isSelected: index === 0 ? true : false,
        };
      });
      setOpen(temp.length === 0 ? true : false);
      setAssessment(temp);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getEssayById();
  }, []);

  return (
    <>
      {loading && <LoadingOverlay />}

      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Admission Attempt Spring 2024</h1>

          <div className={styles.header_buttons}>
            <button onClick={handleReturn} className={styles.return}>
              <ArrowBackIosIcon />
              <span>Return to Dashboard</span>
            </button>
            <button onClick={() => setOpen(!open)} className={styles.add_essay}>
              <AddIcon />
              <span>Add New Essay</span>
            </button>
          </div>
        </div>
        <div className={styles.border}></div>

        <div className={styles.all}>
          <div className={styles.sub_container_left}>
            {assessment.length === 0 && (
              <div className={styles.assessment_section}>
                <p>You have not posted any content for assessment yet.</p>
              </div>
            )}
            {assessment.map((item, index) => {
              if (item.isSelected) {
                return (
                  <div
                    className={styles.sub_container_left_bottom}
                    onClick={() => handleSelectedQuestion(index)}
                  >
                    <p>{item.createdAt}</p>
                    <h5>
                      {item.status === 'done' && <DoneIcon fill={'#2AA87E'} />}
                      {item.status === 'review' && (
                        <ProgressIcon fill={'#0AA6E0'} />
                      )}
                      {item.status === 'progress' && <PendingIcon />}
                      {item.essayContent.title.length > 60
                        ? item.essayContent.title.substring(0, 60) + '...'
                        : item.essayContent.title}
                    </h5>
                    <h4>{item.author}</h4>
                  </div>
                );
              } else {
                return (
                  <div
                    className={styles.sub_container_left_top}
                    onClick={() => handleSelectedQuestion(index)}
                  >
                    <p>{item.createdAt}</p>

                    <h5>
                      {item.status === 'done' && <DoneIcon fill={'#2AA87E'} />}
                      {item.status === 'review' && (
                        <ProgressIcon fill={'#0AA6E0'} />
                      )}
                      {item.status === 'progress' && <PendingIcon />}
                      {item.essayContent.title.length > 60
                        ? item.essayContent.title.substring(0, 60) + '...'
                        : item.essayContent.title}
                    </h5>
                    <h4>{item.author}</h4>
                  </div>
                );
              }
            })}
          </div>
          <div className={styles.essay_section}>
            {open && (
              <AddEssay
                navigate={navigate}
                admissionId={admissionId}
                setLoading={setLoading}
                getEssayById={getEssayById}
                setOpen={setOpen}
              />
            )}
            <div className="sub_container_right_section">
              {assessment.map((item, index) => {
                if (item.isSelected) {
                  return <Question item={item} isSelected={item.isSelected} />;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmittedAssessment;
