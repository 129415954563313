import { yupResolver } from '@hookform/resolvers/yup';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Stack, Typography } from '@mui/material';
import LoadingOverlay from 'components/LoadingOverlay';
import { useLoggedUser } from 'contexts/UserContext';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { colorPalette } from 'theme/colors';
import { blankCourseInfo } from './const/data';
import { courseAdd } from './validations/addSchema';

import { useCourseContext } from 'pages/Settings/context';
import { FormField } from '../common';

export const CourseAdd = () => {
  const { user } = useLoggedUser();
  const [courseInfo, setCourseInfo] = useState(blankCourseInfo);
  const { isFetching, post, isPosting, regions, schools } = useCourseContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(courseAdd),
    mode: 'onChange',
  });

  const handleCourseSubmit = async (data) => {
    const { assignedCoaches, ...withoutCoaches } = data;
    const postData = {
      ...withoutCoaches,
      organizationId: user.organization,
    };
    await post(postData);

    setCourseInfo(blankCourseInfo);
    reset();
  };

  const renderFormFields = () => {
    const fields = [
      { name: 'courseName', label: 'Course Code', type: 'text' },
      {
        name: 'assignedRegion',
        label: 'Region',
        type: 'select',
        options: regions,
      },
      {
        name: 'assignedSchool',
        label: 'School',
        type: 'select',
        options: schools,
      },
    ];

    return fields.map((field) => (
      <FormField
        formType="ADD"
        key={field.name}
        field={field}
        register={register}
        errors={errors}
        watch={watch}
        state={courseInfo}
        stateUpdater={setCourseInfo}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
        loading={isFetching}
      />
    ));
  };

  return (
    <Box
      sx={{
        background: colorPalette.background.paper,
        px: 2,
        py: 4,
        borderRadius: 2,
        paddingBottom: 4,
      }}
      component="form"
      onSubmit={handleSubmit(handleCourseSubmit)}
    >
      {isPosting ? <LoadingOverlay /> : null}
      <Stack direction="column" spacing={2}>
        <Typography variant="h6">Add Course</Typography>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {renderFormFields()}

          <Button
            variant="contained"
            color="success"
            startIcon={<SendIcon />}
            type="submit"
          >
            Create Course
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CourseAdd;
