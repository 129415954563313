import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DoneIcon, ProgressIcon } from 'svg/CustomSVGIcon';
import styles from '../styles/LearnerDashboard.module.css';

import LoadingOverlay from 'components/LoadingOverlay';

import { Group, Person } from '@mui/icons-material';
import organizationAPI from 'api/api';
import { CustomButton } from 'components/Button';
import { CustomLinearProgress } from 'components/Progress';
import { CustomTextField } from 'components/TextField';
import { useAuthStore } from 'store';

const LearnerDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [canvasName, setCanvasName] = useState('');
  const [canvasList, setCanvasList] = useState([]);

  const user = useAuthStore((state) => state.loggedUser);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [startNew, setStartNew] = React.useState(false);
  const navigate = useNavigate();

  const handleStart = async (e) => {
    setLoading(true);
    e.preventDefault();

    const organizationCode =
      await organizationAPI.getOrganizationCodeFromLocalStorage();

    const data = {
      canvasName: canvasName,
      userId: user._id,
      organizationId: organizationCode,
    };

    const response = await organizationAPI.createCanvas(data);
    setLoading(false);
    setOpen(false);

    navigate(`/student/learner/canvas/${response.data._id}`);
  };

  const getAllCanvas = async () => {
    setLoading(true);
    const response = await organizationAPI.getAllCanvas(user?._id);
    setCanvasList(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getAllCanvas();
  }, [user]);

  const convertDate = (dateString) => {
    return moment(dateString).format('dddd • MMMM D, YYYY • h:mm A');
  };

  const handleCanvasNavigation = (canvasId) => (e) => {
    e.preventDefault();
    navigate(`/student/learner/canvas/${canvasId}`);
  };
  return (
    <>
      {loading && <LoadingOverlay />}

      <h1>Welcome, {user && user.firstName + ' ' + user.lastName}</h1>

      <div className={styles.learner_header}>
        <h2>Lean Business Model Canvas Iterations</h2>
        <div className={styles.learner_header_buttons}>
          <CustomTextField
            className={styles.search}
            label="Search"
            placeholder="Search for a student, keyword, ITR, date, year, month or time..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <CustomButton className={styles.iteration} onClick={handleOpen}>
            <AddIcon /> Create Lean Canvas
          </CustomButton>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={styles.modal_view}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Start My Lean Business Model Canvas
              </Typography>

              <div className={styles.border}></div>
              <div className={styles.modal_content}>
                <CustomTextField
                  label="NAME OF YOUR BUSINESS"
                  placeholder="Business Name"
                  multiline
                  value={canvasName}
                  onChange={(e) => setCanvasName(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  className={styles.name_input}
                />

                <CustomButton onClick={handleStart} className={styles.start}>
                  Start Now
                </CustomButton>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      <div className={styles.coach_roaster}>
        <div className={styles.learner_table}>
          <table className="styled_table">
            <thead className="table_headers">
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Group</th>
                <th>Status</th>
                <th>Score</th>
                <th>Progress</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {canvasList.length > 0 &&
                canvasList
                  .filter((item) => {
                    if (search === '' || search === undefined) {
                      return item;
                    } else if (
                      item.canvasName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      convertDate(item.createdAt)
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      item.status.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      item.canvasName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => {
                    return (
                      <tr className={styles.coachrow} key={index}>
                        <td className={styles.data}>
                          {convertDate(item.createdAt)}
                        </td>
                        <td className={styles.data}>{item.canvasName}</td>
                        <td className={styles.data}>
                          {item.additionalMembers.length > 0 ? (
                            <div className={styles.group}>
                              <Group /> <p>Group</p>
                            </div>
                          ) : (
                            <div className={styles.group}>
                              <Person />
                              <p>Personal</p>
                            </div>
                          )}
                        </td>

                        <td className={styles.data}>
                          <div
                            className={
                              item.status === 'progress'
                                ? styles.progress
                                : item.status === 'review'
                                ? styles.review
                                : styles.done
                            }
                          >
                            {item.status === 'progress' ? (
                              ''
                            ) : item.status === 'review' ? (
                              <ProgressIcon />
                            ) : (
                              <DoneIcon />
                            )}

                            {item.status.toUpperCase()}
                          </div>
                        </td>

                        <td className={styles.data}>
                          {item.gradings.length > 0
                            ? item.gradings[item.gradings.length - 1].tiles.map(
                                (tile) => {
                                  if (tile.name === 'overall') {
                                    return tile.score;
                                  }
                                }
                              )
                            : 'Not Graded Yet'}
                        </td>
                        <td className={styles.data}>
                          <CustomLinearProgress
                            value={item.progressPercentage}
                          />
                        </td>

                        <td className={styles.data}>
                          <div className={styles.status}>
                            <div className={styles.resend}>
                              <VisibilityOutlinedIcon />
                              <button
                                onClick={handleCanvasNavigation(item._id)}
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {canvasList.length === 0 && (
            <div className={styles.empty_table}>
              <p>No Business Canvas listed yet.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LearnerDashboard;
