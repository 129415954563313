import organizationAPI from 'api/api';
import { EmptyState, PersonaCard } from 'components/CustomMUI';
import LoadingOverlay from 'components/LoadingOverlay';
import { useApi } from 'hooks';
import useNotification from 'hooks/useNotification';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
import useNotificationStore from 'store/useNotificationStore';
import useStore from 'store/useStore';
import styles from './styles/Style.module.css';

const Assignment = () => {
  const { personas, setPersonas, selectFirstPersona } = usePersonaStore();
  const { notification } = useNotificationStore();
  const { show, NotificationComponent } = useNotification();
  const { setPathName, userId } = useStore();
  console.log(userId);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const { call, isLoading: loading } = useApi({
    fetcher: organizationAPI.getconvoAssignment,
    successMessage: 'All persona fetched successfully',
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await call();
      if (response?.data) {
        setPersonas(response.data);
      }
    };

    fetchData();
    setPathName(window.location.pathname);
  }, []);

  useEffect(() => {
    if (notification) {
      show(notification.message, notification.type);
    }
  }, [notification, show]);

  return (
    <>
      {!notification && loading ? <LoadingOverlay></LoadingOverlay> : null}
      {notification && !loading ? (
        <NotificationComponent
          notification={notification.message}
          type={notification.type}
        />
      ) : null}
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.navbar_container}>
            <div className={styles.navbar}>
              <h1>My Assignments</h1>
              <div className={styles.nav_right}>
                {/* <button className={styles.nav_btn2} onClick={handleBackClick}>
                  <ChevronLeftIcon />
                  <p>Return to Conversations</p>
                </button> */}
              </div>
            </div>
            <div className={styles.border}></div>
          </div>
          <div className={styles.container}>
            <div className={styles.persona_container}>
              {personas && personas.length > 0 ? (
                personas.map((item, index) => (
                  <PersonaCard data={item} key={index} />
                ))
              ) : (
                <EmptyState />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Assignment;
