import React, { createContext, useEffect, useState } from 'react';
import useStore from 'store/useStore';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { setUserId, setOrganizationId } = useStore();
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : {}; // Default to empty object
  });

  const updateUser = (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
  };

  const clearUser = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      const userdata = JSON.parse(storedUser);
      setUserId(userdata._id);
      setOrganizationId(userdata.organization);
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, updateUser, clearUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useLoggedUser = () => {
  return React.useContext(UserContext);
};
