import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { EmptyState, Widget } from 'components/CustomMUI';
import useFormattedData from 'hooks/useFormattedData';
import prettyMilliseconds from 'pretty-ms';

const KeyMetrics = ({ data }) => {
  const { formattedNum } = useFormattedData();
  return (
    <Box
      sx={{
        width: '95%',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Typography variant="h6" sx={{ color: '#000' }}>
        Key Performance Metrics
      </Typography>
      <Divider sx={{ borderColor: '#DAE8E7', marginY: 1 }} />
      {data ? (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 210px)',
            gridTemplateRows: 'repeat(2, 140px)',
            gap: `${5 * 8}px ${10 * 8}px`,
          }}
        >
          <Widget
            title={'Total Calls Duration'}
            info={prettyMilliseconds(data.totalCallDuration.value)}
            percentage={formattedNum(data.totalCallDuration.percentage)}
            increase={data.totalCallDuration.hasIncreased}
          />
          <Widget
            title={'Total Calls'}
            info={data.totalCalls.value}
            percentage={formattedNum(data.totalCalls.percentage)}
            increase={data.totalCalls.hasIncreased}
          />
          <Widget title={'My Team'} info={data.teamMembers} />
          <Widget
            title={'Average Scorecard'}
            info={`${data.averageScore.value} / ${data.averageMaxScore}`}
            percentage={formattedNum(data.averageScore.percentage)}
            increase={data.averageScore.hasIncreased}
          />
        </Box>
      ) : (
        <EmptyState />
      )}
    </Box>
  );
};

export default KeyMetrics;
