import {
  Checklist,
  Insights,
  Leaderboard,
  TipsAndUpdates,
} from '@mui/icons-material';

import organizationAPI from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { Tab } from 'components/Tab';
import { useLoggedUser } from 'contexts/UserContext';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from './components/Header';
import { AssessmentAnswer, GradeSummary, Performance, Tips } from './Tabs';

const Dashboard = () => {
  const [loading, setLoading] = useState(false);

  const { user: loggedUser } = useLoggedUser();
  // const [activeMenu, setActiveMenu] = useState('grade');
  const [gradeSummary, setGradeSummary] = useState(null);
  const [createdBy, setCreatedBy] = useState('');
  const [tips, setTips] = useState(null);

  const location = useLocation();
  const assessmentId = location.pathname.split('/')[5];
  const studentId = location.pathname.split('/')[4];

  // const handleCandidateNavigation = (e) => {
  //   navigate(`/coach/psych/candidates/${assessmentId}`);
  // };

  const getAssessmentTips = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAssessmentEvalutionTips(
        gradeSummary.assessmentEvaluationId
      );
      setTips(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getAssessmentSummary = async () => {
    const queryParams = new URLSearchParams(location.search);
    const evaluationId = queryParams.get('evaluationId');

    setLoading(true);
    try {
      const response = await organizationAPI.getAssessmentGradeSummary(
        studentId,
        assessmentId,
        evaluationId
      );

      setGradeSummary(response.data);
      setCreatedBy(response.data.createdBy);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAssessmentSummary();
  }, []);

  useEffect(() => {
    if (gradeSummary) {
      getAssessmentTips();
    }
  }, [gradeSummary]);

  const tabItems = [
    {
      label: 'Grade Summary',
      content: <GradeSummary gradeSummary={gradeSummary?.scores} />,
      icon: <Leaderboard />, // Replace with actual icon
    },
    {
      label: 'Assessment Answers',
      content: (
        <AssessmentAnswer
          assessmentAnswer={gradeSummary?.assessment.sections}
        />
      ),
      icon: <Checklist />, // Replace with actual icon
    },
    {
      label: 'Tips',
      content: <Tips assessmentAnswer={tips} />,
      icon: <TipsAndUpdates />, // Replace with actual icon
    },
    {
      label: 'Performance',
      content: <Performance assessmentAnswer={tips} />,
      icon: <Insights />, // Replace with actual icon
    },
  ];

  return (
    <>
      {loading && <LoadingOverlay />}
      <Header
        assessmentName={gradeSummary?.assessment?.assessmentName}
        createdBy={createdBy}
      />
      <Tab tabItems={tabItems} />
    </>
  );
};

export default Dashboard;
