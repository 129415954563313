import { personaType } from 'api/config';
import { create } from 'zustand';

const usePersonaStore = create((set) => ({
  personas: [],
  behaviors: [],
  selectedPersona: JSON.parse(localStorage.getItem('selectedPersona')) || null,
  docId: localStorage.getItem('docId') || null,
  personaBehavior: JSON.parse(localStorage.getItem('personaBehavior')) || null,

  setPersonas: (personas) => set({ personas }),

  setBehaviors: (behaviors) => set({ behaviors }),

  selectPersona: (personaId) => {
    set((state) => {
      const selected =
        state.personas.find((persona) => persona._id === personaId) || null;
      if (selected) {
        localStorage.setItem('selectedPersona', JSON.stringify(selected));
      } else {
        localStorage.removeItem('selectedPersona');
      }
      return { selectedPersona: selected };
    });
  },

  selectFirstPersona: () => {
    set((state) => {
      const buyerPersonas = state.personas.filter(
        (persona) => persona.type === personaType
      );

      if (buyerPersonas.length > 0 && !state.selectedPersona) {
        const firstBuyerPersona = buyerPersonas[0];
        localStorage.setItem(
          'selectedPersona',
          JSON.stringify(firstBuyerPersona)
        );
        return { selectedPersona: firstBuyerPersona };
      }
      return {};
    });
  },

  clearSelectedPersona: () => {
    set({ selectedPersona: null });
    localStorage.removeItem('selectedPersona');
  },

  setDocId: (docId) => {
    set({ docId });
    if (docId) {
      localStorage.setItem('docId', docId);
    } else {
      localStorage.removeItem('docId');
    }
  },

  clearDocId: () => {
    set({ docId: null });
    localStorage.removeItem('docId');
  },

  setPersonaBehavior: (behavior) => {
    set({ personaBehavior: behavior });
    localStorage.setItem('personaBehavior', JSON.stringify(behavior));
  },

  clearPersonaBehavior: () => {
    set({ personaBehavior: null });
    localStorage.removeItem('personaBehavior');
  },

  clearBehaviors: () => {
    set({ behaviors: [] });
  },

  selectPersonaBehavior: (selectedMood, selectedTone, selectedDifficulty) => {
    set((state) => {
      const updatedBehavior = {
        mood: selectedMood,
        tone: selectedTone,
        difficulty: selectedDifficulty,
      };
      localStorage.setItem('personaBehavior', JSON.stringify(updatedBehavior));
      return { personaBehavior: updatedBehavior };
    });
  },
}));

export default usePersonaStore;
