import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useState } from 'react';

function valuetext(value) {
  return `${value}`;
}

const CustomSlider = ({ onChange }) => {
  const [value, setValue] = useState([0, 100]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange({
        minValue: newValue[0],
        maxValue: newValue[1],
      });
    }
  };

  return (
    <Box sx={{ width: '100%', mt: 1, zIndex: 1 }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
    </Box>
  );
};

export default CustomSlider;
