import AddIcon from '@mui/icons-material/Add';
import { Box, Modal, Typography } from '@mui/material';
import organizationAPI from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/Dashboard.module.css';

import {
  Archive,
  Edit,
  ExpandLess,
  ExpandMore,
  PeopleAlt,
  RemoveCircleOutline,
  Send,
} from '@mui/icons-material';
import { ToastContainer } from 'react-toastify';

import { CustomTextField } from 'components/TextField';
import { useLoggedUser } from 'contexts/UserContext';

const RemoveSectionModal = ({
  selectedAssessment,
  open,
  setOpen,
  setLoading,
  getAssessment,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const handleRemoveQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await organizationAPI.deleteAssessment(
        selectedAssessment._id
      );
      await getAssessment();
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <RemoveCircleOutline
            style={{
              color: '#E03838',
              fontSize: '40px',
            }}
          />
          <h5>Delete Section</h5>
          <p>
            Are you sure you want to delete this section? This action cannot be
            undone.
          </p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleRemoveQuestion} className={styles.remove}>
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const CreateModal = ({ open, setOpen, setLoading, navigate }) => {
  const [disableButton, setDisableButton] = useState(true);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const [assessmentName, setAssessmentName] = useState('');

  const createAssessment = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      assessmentName,
    };
    try {
      const response = await organizationAPI.createAssessment(data);
      navigate(`/coach/psych/details/${response.data._id}`);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setOpen(false);
  };

  useEffect(() => {
    if (assessmentName.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [assessmentName]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <h3>Create New Assessment</h3>
        </Typography>

        <div className={styles.modal_buttons}>
          <CustomTextField
            label="Assessment Name"
            placeholder={'Enter Assessment Name'}
            value={assessmentName}
            onChange={(e) => setAssessmentName(e.target.value)}
          />
          <button
            onClick={createAssessment}
            className={styles.create}
            disabled={disableButton}
          >
            Start Now
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(-1);
  const navigate = useNavigate();
  const { user: loggedUser } = useLoggedUser();
  const [assessmentList, setAssessmentList] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const getAssessmentList = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getAssessment();
      setAssessmentList(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateAssessmentStatus = async (id, status) => {
    setLoading(true);
    const data = {
      status,
    };
    try {
      await organizationAPI.updateAssessment(id, data);
      getAssessmentList();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAssessmentList();
  }, []);

  return (
    <>
      {loading && <LoadingOverlay />}
      <CreateModal
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        setLoading={setLoading}
        getAssessmentList={getAssessmentList}
        navigate={navigate}
      />
      <RemoveSectionModal
        open={removeModalOpen}
        setOpen={setRemoveModalOpen}
        setLoading={setLoading}
        selectedAssessment={selectedAssessment}
        getAssessment={getAssessmentList}
      />
      <div className={styles.content}>
        <div className={styles.learner_header}>
          <h1>Assessments</h1>

          <div className={styles.learner_header_buttons}>
            <button
              className={styles.iteration}
              onClick={() => setCreateModalOpen(true)}
            >
              <AddIcon />
              Create New Assessment
            </button>
          </div>
        </div>
        <div className={styles.border}></div>

        <div className={styles.coach_roaster}>
          <div className={styles.learner_table}>
            {assessmentList.length > 0 && (
              <table className="styled_table">
                <thead className="table_headers">
                  <tr>
                    <th>Assessment</th>

                    <th>Assessments Taken by</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {assessmentList.map((item, index) => {
                    return (
                      <tr className={styles.coachrow} key={index}>
                        <td className={styles.data}>{item.assessmentName}</td>
                        <td className={styles.data}>{item.evaluationCount}</td>
                        <td className={styles.data}>
                          {item.status.charAt(0).toUpperCase() +
                            item.status.slice(1)}
                        </td>
                        <td className={styles.data}>
                          <div className={styles.status}>
                            <div className={styles.resend}>
                              <p
                                onClick={() => {
                                  if (dropdownIndex === index) {
                                    setDropdownIndex(-1);
                                  } else {
                                    setDropdownIndex(index);
                                  }
                                }}
                              >
                                Action
                              </p>
                              {dropdownIndex === index ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </div>

                            {dropdownIndex === index && (
                              <div className={styles.action}>
                                <ul>
                                  {loggedUser.role === 'ADMIN' && (
                                    <li>
                                      <Send /> <span>Publish</span>
                                    </li>
                                  )}
                                  <li
                                    onClick={() =>
                                      navigate(
                                        `/coach/psych/candidates/${item._id}`
                                      )
                                    }
                                  >
                                    <PeopleAlt /> <span>Candidates</span>
                                  </li>
                                  {loggedUser.role === 'ADMIN' && (
                                    <>
                                      <li
                                        onClick={() =>
                                          navigate(
                                            `/coach/psych/details/${item._id}`
                                          )
                                        }
                                      >
                                        <Edit /> <span>Edit</span>
                                      </li>
                                      <li
                                        onClick={() => {
                                          updateAssessmentStatus(
                                            item._id,
                                            'archived'
                                          );
                                          setDropdownIndex(-1);
                                        }}
                                      >
                                        <Archive /> <span>Archive</span>
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {assessmentList.length === 0 && (
              <div className={styles.empty_table}>
                <p>No Assessments have been created yet..</p>
                <p className={styles.link}>
                  Click here to create a new Assessment.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Dashboard;
