import { useCallback } from 'react';

const useFormattedData = () => {
  const formattedNum = useCallback((num) => {
    const roundedNum = Math.ceil(num * 10) / 10;
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(roundedNum);
  }, []);

  const formattedTime = useCallback((totalSeconds) => {
    const date = new Date(totalSeconds * 1000);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    return `${hours}h ${minutes}m ${seconds}s`;
  }, []);

  return { formattedNum, formattedTime };
};

export default useFormattedData;
