import AppContextProvider from 'contexts/AppContext';
import { AppRoutes } from 'routes';
import 'rsuite/DateRangePicker/styles/index.css';
import './styles/App.css';

function App() {
  return (
    <AppContextProvider>
      <AppRoutes />
    </AppContextProvider>
  );
}

export default App;
