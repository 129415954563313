import moment from 'moment';
import { useEffect, useState } from 'react';
import styles from './styles/Tutor.module.css';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import LoadingOverlay from 'components/LoadingOverlay';
import { SidebarCollapseIcon, TutorIcon } from 'svg/CustomSVGIcon';

import { Box, Button, InputAdornment, Modal, TextField } from '@mui/material';
import organizationAPI from 'api/api';

import { AccountCircleRounded } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { CustomTextField } from 'components/TextField';
import { useNavigate } from 'react-router-dom';
const RenameChatModal = ({
  open,
  setOpen,
  setLoading,
  chatId,
  getTeacherAIChatData,
  setMenuActive,
}) => {
  const [name, setName] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const handleCreateChat = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      name,
      teacherAIChatId: chatId,
    };
    try {
      await organizationAPI.renameTeacherAIChat(data);
    } catch (error) {
      console.log(error);
    }
    await getTeacherAIChatData();
    setName('');
    setOpen(false);
    setLoading(false);
    setMenuActive(false);
  };

  useEffect(() => {
    if (name.length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [name]);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.remove_modal}>
        <h2>Rename your chat</h2>
        <CustomTextField
          label="Type the name of the chat"
          placeholder="Type the name of the chat"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          multiline
        />
        <button onClick={handleCreateChat} disabled={buttonDisabled}>
          Save
        </button>
      </Box>
    </Modal>
  );
};
const CreateChatModal = ({
  open,
  setOpen,
  setLoading,
  getTeacherAIChatData,
}) => {
  const [question, setQuestion] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const handleCreateChat = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      question,
      teacherAIId: '65fbc479837994a78f9f45d2',
    };
    try {
      await organizationAPI.createTeacherAIChat(data);
    } catch (error) {
      console.log(error);
    }
    await getTeacherAIChatData();
    setQuestion('');
    setOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    if (question.length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [question]);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.remove_modal}>
        <h2>Start New Chat</h2>
        <CustomTextField
          label="TYPE YOUR PROMPT HERE"
          placeholder="Message the NFTE Teacher AI"
          value={question}
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
          multiline
        />
        <button onClick={handleCreateChat} disabled={buttonDisabled}>
          Create
        </button>
      </Box>
    </Modal>
  );
};
const TutorAI = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(false);
  const [sidebarCollapse, setSidebarCollapse] = useState(true);
  const [user, setUser] = useState(null);
  const [thread, setThread] = useState([]);
  const [questionId, setQuestionId] = useState('');

  const [chatModal, setChatModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [chatId, setChatId] = useState('');

  const [replyBody, setReplyBody] = useState('');
  const [disableReplyButton, setDisableReplyButton] = useState(true);
  const handleBack = (e) => {
    e.preventDefault();
    navigate('/student/admission/dashboard');
  };
  useEffect(() => {
    if (replyBody.length > 0) {
      setDisableReplyButton(false);
    } else {
      setDisableReplyButton(true);
    }
  }, [replyBody]);

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getTeacherAIChatData = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getTeacherAIChat();
      const temp = response.data.reverse().map((chat, index) => {
        if (index === 0) {
          setQuestionId(chat.questionId);
          return {
            ...chat,
            createdAt: convertDate(chat.createdAt),
            active: true,
          };
        } else {
          return {
            ...chat,
            createdAt: convertDate(chat.createdAt),
            active: false,
          };
        }
      });

      const grouped = temp.reduce((r, a) => {
        r[a.createdAt] = [...(r[a.createdAt] || []), a];
        return r;
      }, {});

      const groupedArray = Object.keys(grouped).map((key) => {
        return { date: key, thread: grouped[key] };
      });
      setThread(groupedArray);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const convertDate = (dateString) => {
    return moment(dateString).format('dddd • MMMM D, YYYY');
  };

  const handleDeleteChat = async (id) => {
    setLoading(true);
    try {
      const response = await organizationAPI.deleteTeacherAIChat(id);
      getTeacherAIChatData();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setMenuActive(false);
  };

  const handleChatSubmit = async () => {
    setLoading(true);
    const data = {
      question: replyBody,
      teacherAIId: '65fbc479837994a78f9f45d2',
      questionId: questionId,
    };

    try {
      await organizationAPI.createTeacherAIChat(data);
      getTeacherAIChatData();
    } catch (error) {
      console.log(error);
    }
    setReplyBody('');
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    getTeacherAIChatData();
  }, []);
  return (
    <>
      {loading && <LoadingOverlay />}
      <CreateChatModal
        open={chatModal}
        setOpen={setChatModal}
        setLoading={setLoading}
        getTeacherAIChatData={getTeacherAIChatData}
      />
      <RenameChatModal
        open={renameModal}
        setOpen={setRenameModal}
        setLoading={setLoading}
        chatId={chatId}
        getTeacherAIChatData={getTeacherAIChatData}
        setMenuActive={setMenuActive}
      />

      <div className={styles.content}>
        <h1>NFTE Teacher AI</h1>
        <div className={styles.border}></div>
        <div className={styles.coach_roaster}>
          {sidebarCollapse && (
            <div className={styles.coach_roaster_left}>
              <>
                <ul>
                  <h2>NFTE Teacher AI</h2>
                  <button
                    onClick={() => setChatModal(true)}
                    className={styles.create_chat_button}
                  >
                    <span>Start New Chat</span>
                    <NoteAltOutlinedIcon />
                  </button>

                  {
                    thread?.length > 0 &&
                      thread.map((threadByDate) => {
                        return (
                          <>
                            <li>
                              <h5>{threadByDate.date}</h5>
                              {threadByDate.thread.map((chat) => (
                                <p
                                  className={
                                    chat.active && styles.thread_active
                                  }
                                  onClick={() => {
                                    const updatedThread = thread.map(
                                      (dateThread) => ({
                                        ...dateThread,
                                        thread: dateThread.thread.map((c) => ({
                                          ...c,
                                          active:
                                            c._id === chat._id ? true : false,
                                        })),
                                      })
                                    );
                                    setQuestionId(chat.questionId);
                                    setThread(updatedThread);
                                  }}
                                >
                                  <span>
                                    {chat.name
                                      ? chat.name.length > 45
                                        ? chat.name.substring(0, 45) + '...'
                                        : chat.name
                                      : chat.thread[0].question.length > 45
                                      ? chat.thread[0].question.substring(
                                          0,
                                          45
                                        ) + '...'
                                      : chat.thread[0].question}
                                  </span>
                                  {chat.active && (
                                    <>
                                      <MoreVertIcon
                                        onClick={() =>
                                          setMenuActive(!menuActive)
                                        }
                                      />

                                      {menuActive && (
                                        <ul>
                                          <li
                                            onClick={() => {
                                              setRenameModal(true);
                                              setChatId(chat._id);
                                            }}
                                          >
                                            Rename
                                          </li>
                                          <li
                                            onClick={() => {
                                              handleDeleteChat(chat._id);
                                            }}
                                          >
                                            Delete
                                          </li>
                                        </ul>
                                      )}
                                    </>
                                  )}
                                </p>
                              ))}
                            </li>
                          </>
                        );
                      })
                    // .map((chat) => (
                    //   <li key={chat._id}>
                    //     <h5>{chat.createdAt}</h5>
                    //     <p
                    //       className={chat.active && styles.thread_active}
                    //       onClick={() => {
                    //         const temp = thread.map((chat) => {
                    //           return {
                    //             ...chat,
                    //             active: false,
                    //           };
                    //         });
                    //         temp[thread.indexOf(chat)] = {
                    //           ...chat,
                    //           active: true,
                    //         };
                    //         setThread(temp);
                    //       }}
                    //     >
                    //       {chat.thread[0].question}
                    //     </p>
                    //   </li>
                    // ))
                  }

                  {/* <li>
                    <h5>TODAY</h5>
                    <p>Topic example topic example nothing to see here</p>
                    <p>Topic example topic example nothing to see here</p>
                  </li>
                  <li>
                    <h5>YESTERDAY</h5>
                    <p>Topic example topic example nothing to see here</p>
                    <p>Topic example topic example nothing to see here</p>
                  </li>
                  <li>
                    <h5>LAST 7 DAYS</h5>
                    <p>Topic example topic example nothing to see here</p>
                    <p>Topic example topic example nothing to see here</p>
                  </li>

                  <li>
                    <h5>OLDER</h5>
                    <p>Topic example topic example nothing to see here</p>
                  </li> */}
                </ul>
              </>
            </div>
          )}
          <div className={styles.coach_roaster_right}>
            <SidebarCollapseIcon
              onClick={() => {
                setSidebarCollapse(!sidebarCollapse);
              }}
            />
            <div className={styles.post_reply}>
              <div className={styles.top}>
                <div className={styles.student_profile}>
                  <TutorIcon />
                  <h4>NFTE Teacher AI</h4>
                </div>
                {/* <div className={styles.icons}>
                  <ThumbUpOutlinedIcon />
                  <ThumbDownOffAltOutlinedIcon />
                  <ReplayOutlinedIcon />
                </div> */}
              </div>
              <p>Hi! Ready to roll?</p>
            </div>
            <div className={styles.thread_section}>
              {thread?.length > 0 &&
                thread
                  .filter((chat) =>
                    chat.thread.some(
                      (singleThread) => singleThread.active === true
                    )
                  )[0]
                  .thread.map((singleThread) => {
                    if (singleThread.active) {
                      return singleThread.thread.map((chat) => {
                        return (
                          <>
                            <div className={styles.post_reply} key={chat._id}>
                              <div className={styles.top}>
                                <div className={styles.student_profile}>
                                  <AccountCircleRounded
                                    style={{
                                      color: '#4c12a1',
                                    }}
                                  />
                                  <h4>You</h4>
                                </div>
                                {/* <div className={styles.icons}>
                              <ModeEditOutlineOutlinedIcon />
                            </div> */}
                              </div>
                              <p>{chat.question}</p>
                            </div>
                            <div className={styles.post_reply}>
                              <div className={styles.top}>
                                <div className={styles.student_profile}>
                                  <TutorIcon />
                                  <h4>NFTE Teacher AI</h4>
                                </div>
                                {/* <div className={styles.icons}>
                              <ThumbUpOutlinedIcon />
                              <ThumbDownOffAltOutlinedIcon />
                              <ReplayOutlinedIcon />
                            </div> */}
                              </div>

                              <p>{chat.answer}</p>

                              {/* <ol type="1">
                <li>Purpose: Clarify what you want to measure.</li>
                <li>Choose/Test: Select or create a suitable test.</li>
                <li>Collect Data: Administer the test and gather data.</li>
                <li>Reliability: Check consistency and stability.</li>
                <li>Validity: Ensure the test measures what it claims.</li>
                <li>Item Analysis: Review individual test questions.</li>
                <li>
                  Factor Analysis: Identify underlying patterns if needed.
                </li>
                <li>Norming: Compare scores to a representative sample.</li>
                <li>Interpret Data: Analyze and consider limitations.</li>
                <li>Documentation: Prepare a detailed report.</li>
                <li>Refinement: Review and improve as needed.</li>
              </ol>  */}
                              <h6>SOURCES</h6>
                              <div className={styles.document_section}>
                                {chat.sources.map((source, index) => (
                                  <span key={index}>
                                    <a href={source.url}>{source.name}</a>
                                  </span>
                                ))}
                              </div>
                            </div>
                          </>
                        );
                      });
                    }
                  })}
            </div>
            <div className={styles.post_reply_input}>
              {thread?.length > 0 && (
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { width: '100%', marginLeft: '0' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-controlled"
                    label="TYPE YOUR PROMPT HERE"
                    placeholder="Message the NFTE Teacher AI..."
                    multiline
                    value={replyBody}
                    onChange={(e) => setReplyBody(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            className={
                              disableReplyButton
                                ? styles.disableReplyButton
                                : styles.post_reply
                            }
                            // variant="outlined"
                            style={{ fontWeight: 'bold' }}
                            disabled={disableReplyButton}
                            onClick={handleChatSubmit}
                          >
                            {/* <UploadOutlinedIcon /> */}
                            <SendOutlinedIcon />
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TutorAI;
