import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './styles.css';
export const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        gap: 2,
      }}
    >
      <div className="floating-icon">
        <SentimentDissatisfiedIcon color="primary" sx={{ fontSize: 100 }} />
      </div>
      <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
        404
      </Typography>
      <Typography variant="h5" gutterBottom>
        Page Not Found
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
        The page you are looking for might have been removed or is temporarily
        unavailable.
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleGoBack}
          className="hover-button"
        >
          Go Back
        </Button>
      </Box>
    </Container>
  );
};
