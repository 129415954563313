import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';

const cellStyles = {
  border: 'none',
  color: '#5B706F',
  fontWeight: 300,
  fontFamily: 'Lexend',
  fontSize: '14px',
  textTransform: 'capitalize',
  padding: '16px',
};

const IndividualCallHistory = ({ data }) => {
  const navigate = useNavigate();
  const { setDocId } = usePersonaStore();
  const handleCallDetails = () => {
    setDocId(data.id);
    navigate('/sales-representative/call-details');
  };

  if (data) {
    return (
      <Box sx={{ width: '98%', padding: '0 10px' }}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 'none', border: 'none', width: '100%' }}
        >
          <Table sx={{ borderCollapse: 'separate', borderSpacing: 0 }}>
            <TableBody>
              <TableRow>
                <TableCell sx={{ ...cellStyles, width: '15%' }}>
                  {data.title}
                </TableCell>
                <TableCell sx={{ ...cellStyles, width: '15%' }}>AI</TableCell>
                <TableCell sx={{ ...cellStyles, width: '20%' }}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                  >
                    <CalendarTodayIcon />
                    <Typography>{data.createdAt}</Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ ...cellStyles, width: '15%' }}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                  >
                    <LocalPhoneIcon />
                    <Typography>{data.duration}</Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ ...cellStyles, width: '8%' }}>
                  <Typography>{data.mood}</Typography>
                </TableCell>
                <TableCell sx={{ ...cellStyles, width: '8%' }}>
                  <Typography>{data.tone}</Typography>
                </TableCell>
                <TableCell sx={{ ...cellStyles, width: '8%' }}>
                  <Typography>{data.difficulty}</Typography>
                </TableCell>
                <TableCell sx={{ ...cellStyles, width: '8%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <IconButton onClick={handleCallDetails}>
                      <VisibilityIcon sx={{ color: '#00706C' }} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  } else {
    return null;
  }
};

export default IndividualCallHistory;
