import { RotateLeft, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import { CustomSlider, TagInput } from 'components/CustomMUI';
import { DateRangePicker } from 'components/Datepicker';
import { useEffect, useRef, useState } from 'react';
import { useApi } from './../../../../hooks/useApi';

const defaultFilterData = {
  courses: [],
  startDate: '',
  endDate: '',
  keyword: '',
  progressMin: 0,
  progressMax: 100,
  status: '',
  minScore: '',
  maxScore: '',
};

const CanvasFilter = ({ onFilterChange }) => {
  const [filterData, setFilterData] = useState(defaultFilterData);
  const [sliderKey, setSliderKey] = useState(0);
  const datePickerRef = useRef(null);
  const keywordInputRef = useRef(null);
  const [allCourses, setAllCourses] = useState([]);
  const { call } = useApi({
    fetcher: organizationAPI.getAllCourses
  });

  const handleInputChange = (field, value) => {
    const updatedData = { ...filterData, [field]: value };
    setFilterData(updatedData);
    if (onFilterChange) onFilterChange(updatedData);
  };

  const handleResetFilters = () => {
    setFilterData(defaultFilterData);

    
    if (datePickerRef.current?.reset) {
      datePickerRef.current.reset();
    }

    if (keywordInputRef.current) {
      keywordInputRef.current.value = '';
    }

    setSliderKey((prev) => prev + 1);
    if (onFilterChange) {
      onFilterChange(defaultFilterData);
    }
  };

  const formatToDate = (date) => {
    if (!date) return '';
    const localDate = new Date(date);
    localDate.setHours(12, 0, 0, 0);
    return localDate.toISOString().split('T')[0];
  };

  const handleDateRangeChange = (selectedRange) => {
    const { startDate, endDate } = selectedRange || {};

    const formattedStartDate = formatToDate(startDate);
    const formattedEndDate = formatToDate(endDate);

    setFilterData((prev) => ({
      ...prev,
      startDate: formattedStartDate || '',
      endDate: formattedEndDate || '',
    }));

    if (onFilterChange) {
      onFilterChange({
        ...filterData,
        startDate: formattedStartDate || '',
        endDate: formattedEndDate || '',
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await call();
      if (response && response.data.courses) {
        const updatedCourses = response.data.courses.map((course) => ({
          _id: course._id,
          name: course.courseName,
        }));
        setAllCourses(updatedCourses);
      }
    };
    fetchData();
  }, []);

  const handleProgressChange = (selectedRange) => {
    if (!selectedRange) return;

    const { minValue, maxValue } = selectedRange;

    const updatedData = {
      ...filterData,
      progressMin: minValue ?? 0,
      progressMax: maxValue ?? 100,
    };

    setFilterData(updatedData);
    if (onFilterChange) onFilterChange(updatedData);
  };

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: '#fff',
        border: '1px solid #e0e0e0',
        borderRadius: 1,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
        <Box sx={{ position: 'relative', flex: 1 }}>
          <Typography
            sx={{
              position: 'absolute',
              top: '-8px',
              left: '12px',
              backgroundColor: '#fff',
              px: 0.5,
              fontSize: '12px',
              color: 'text.secondary',
              zIndex: 1,
            }}
          >
            KEYWORD
          </Typography>
          <OutlinedInput
            inputRef={keywordInputRef}
            placeholder="Canvas/student name"
            value={filterData.keyword}
            endAdornment={
              <InputAdornment position="end">
                <Search sx={{ color: 'text.secondary' }} />
              </InputAdornment>
            }
            sx={{
              width: '100%',
              height: '55px',
            }}
            onChange={(e) => handleInputChange('keyword', e.target.value)}
          />
        </Box>

        <Box sx={{ position: 'relative', flex: 1 }}>
          <Typography
            sx={{
              position: 'absolute',
              top: '-8px',
              left: '12px',
              backgroundColor: '#fff',
              px: 0.5,
              fontSize: '12px',
              color: 'text.secondary',
              zIndex: 1,
            }}
          >
            STATUS
          </Typography>
          <Select
            displayEmpty
            value={filterData.status}
            onChange={(e) => handleInputChange('status', e.target.value)}
            sx={{
              width: '100%',
              height: '55px',
            }}
          >
            <MenuItem value="">ALL STATUS</MenuItem>
            <MenuItem value="progress">PROGRESS</MenuItem>
            <MenuItem value="done">DONE</MenuItem>
            <MenuItem value="archived">ARCHIVED</MenuItem>
            <MenuItem value="review">REVIEW</MenuItem>
          </Select>
        </Box>

        <Box sx={{ position: 'relative', flex: 1 }}>
          <Typography
            sx={{
              position: 'absolute',
              top: '-8px',
              left: '12px',
              backgroundColor: '#fff',
              px: 0.5,
              fontSize: '12px',
              color: 'text.secondary',
              zIndex: 1,
            }}
          >
            DATE RANGE
          </Typography>
          <DateRangePicker
            ref={datePickerRef}
            onDateChange={handleDateRangeChange}
            isLabel={false}
            startDate={filterData.startDate}
            endDate={filterData.endDate}
            width="100%"
            size="medium"
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Box sx={{ position: 'relative', flex: 2 }}>
          <Typography
            sx={{
              position: 'absolute',
              top: '-8px',
              left: '12px',
              backgroundColor: '#fff',
              px: 0.5,
              fontSize: '12px',
              color: 'text.secondary',
              zIndex: 1,
            }}
          >
            COURSES
          </Typography>
          <TagInput
            data={allCourses}
            value={filterData.courses}
            onChange={(courses) => handleInputChange('courses', courses)}
          />
        </Box>

        <Box
          sx={{
            position: 'relative',
            minWidth: 350,
            border: '1px solid #bdbdbd',
            borderRadius: '5px',
            px: 2,
            height: '55px',
          }}
        >
          <Typography
            sx={{
              position: 'absolute',
              top: '-8px',
              left: '12px',
              backgroundColor: '#fff',
              px: 0.5,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            PROGRESS RANGE
          </Typography>
          <CustomSlider
            key={sliderKey}
            defaultValue={[0, 100]}
            onChange={handleProgressChange}
          />
        </Box>

        <Box
          sx={{
            position: 'relative',
            minWidth: 240,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            height: '55px',
          }}
        >
          <Typography
            sx={{
              position: 'absolute',
              top: '-8px',
              left: '12px',
              backgroundColor: '#fff',
              px: 0.5,
              fontSize: '12px',
              color: 'text.secondary',
              zIndex: 1,
            }}
          >
            SCORE RANGE
          </Typography>
          <OutlinedInput
            placeholder="0"
            value={filterData.minScore}
            onChange={(e) => handleInputChange('minScore', e.target.value)}
            sx={{ width: 120, height: '55px' }}
          />
          <Typography>-</Typography>
          <OutlinedInput
            placeholder="100"
            value={filterData.maxScore}
            onChange={(e) => handleInputChange('maxScore', e.target.value)}
            sx={{ width: 120, height: '55px' }}
          />
        </Box>

        <Button
          variant="outlined"
          startIcon={<RotateLeft />}
          onClick={handleResetFilters}
          sx={{
            height: '55px',
            width: 200,
            borderColor: 'grey.300',
            color: 'text.primary',
            textWrap: 'nowrap',
            '&:hover': {
              borderColor: 'grey.400',
              backgroundColor: 'grey.50',
            },
          }}
        >
          Reset Filters
        </Button>
      </Box>
    </Box>
  );
};

export default CanvasFilter;
