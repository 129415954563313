export const statusColors = {
  neutral: '#00BF8C',
  brusque: '#E03838',
  cautious: '#FF8A03FF',
  curious: '#0ED4D4FF',
  default: '#802B2B',
};

export const moodColors = {
  curious: '#c7a200ff',
  cautious: '#ff2903ff',
  openminded: '#03ff10ff',
  default: '#00c573',
};
export const toneColors = {
  friendly: '#61c503ff',
  curt: '#03a3ffff',
  strict: '#ff2903ff',
  default: '#802B2B',
};
export const difficultyColors = {
  easy: '#00cddbff',
  medium: '#acd104ff',
  hard: '#d80230ff',
  default: '#802B2B',
};
