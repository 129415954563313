import { Stack } from '@mui/material';
import CourseAdd from './CourseAdd';
import CourseList from './CourseList';

const Courses = () => {
  return (
    <Stack spacing={3}>
      <CourseAdd />
      <CourseList />
    </Stack>
  );
};

export default Courses;
