import { GoogleLogin } from '@react-oauth/google';
import adminAPI, { apis } from 'api/api';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Nftelogin.module.css';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { CustomButton } from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import { CustomTextField } from 'components/TextField';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { useAuthStore } from 'store';

const NFTELogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const invitationId = new URLSearchParams(location.search).get('invitationId');
  const organizationCode = location.pathname.split('/')[1];
  const { updateUser } = useLoggedUser();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [recipientEmailErrorMessage, setRecipientEmailErrorMessage] =
    useState('');

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  const [organizationMeta, setOrganizationMeta] = useState({});

  const { call: userLogin, isLoading: isLogginIn } = useApi({
    fetcher: (data) => apis.organizationUserLogin(data),
  });

  const validateEmail = (value) => {
    let currentEmails = value;
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    if (!regex.test(currentEmails.replace(/\s/g, ''))) {
      setRecipientEmailErrorMessage(`Enter valid Email Address`);
    } else {
      setRecipientEmailErrorMessage('');
    }
  };

  useEffect(() => {
    if (email) validateEmail(email);
    else setRecipientEmailErrorMessage('');
  }, [email]);

  useEffect(() => {
    if (
      password !== '' &&
      confirmPassword !== '' &&
      password !== confirmPassword
    ) {
      setPasswordMatch(false);
      setErrorMessage('Passwords do not match');
    } else {
      setPasswordMatch(true);
      setErrorMessage('');
    }
  }, [password, confirmPassword]);

  const saveOrganizationCode = async () => {
    await adminAPI.saveOrganizationCodeToLocalStorage(organizationCode);
  };

  useEffect(() => {
    saveOrganizationCode();
  }, []);

  useEffect(() => {
    if (invitationId) {
      if (password && confirmPassword && passwordMatch) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    } else {
      if (email && password && !recipientEmailErrorMessage) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }, [email, password, confirmPassword, passwordMatch]);

  const [error, setError] = React.useState('');

  const getMe = async () => {
    try {
      const response = await adminAPI.getMe();
      return response.data.user;
    } catch (error) {}
  };

  const roleMeta = async () => {
    try {
      const response = await adminAPI.getOrganizationMetaByRole(
        adminAPI.getOrganizationCodeFromLocalStorage()
      );
      return response.data;
    } catch (error) {}
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const data = {
        invitationId,
        credential: credentialResponse.credential,
        organizationCode,
      };
      const response = await adminAPI.googleSignup(data);
      const metaRoleRespons = await roleMeta();
      const userResponse = await getMe();
      await storeUserDataToLocalStorage(userResponse);

      if (response.status === 200 && response.data.success) {
        if (userResponse.role === 'ADMIN') {
          navigate('/dashboard');
        } else if (userResponse.role === 'COACH') {
          if (metaRoleRespons && metaRoleRespons.role.defaultPath) {
            navigate(metaRoleRespons.role.defaultPath);
          } else {
            navigate('/dashboard');
          }
        } else if (userResponse.role === 'STUDENT') {
          navigate('/student/query-archive');
        }
      } else {
        setError(response.data.error);
      }
    } catch (error) {
      setLoading(false);
      setError('Server Error 500. Please contact with Admin');
    }
  };

  const storeLoginInfoAtGlobalState = useAuthStore((state) => state.login);
  const { call: getLoggedUser } = useApi({ fetcher: apis.getMe });
  const storeUserDataToLocalStorage = async () => {
    const response = await getLoggedUser();
    if (response.data) {
      const { firstName, lastName, role, _id } = response.data.user;
      const userInfo = {
        firstName,
        lastName,
        email,
        role,
        _id,
        organization: response.data.organization_id,
        organizationType: response.data?.organizationType ?? null,
        organizationName: organizationCode,
      };
      updateUser(userInfo);
      storeLoginInfoAtGlobalState(userInfo);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const data = {
        email,
        password,
        organizationCode,
      };

      const response = await userLogin(data);

      const userResponse = await getMe();

      const metaRoleRespons = await roleMeta();

      if (userResponse) await storeUserDataToLocalStorage(userResponse);

      if (response.status === 200 && response.data.success) {
        if (userResponse.role === 'ADMIN') {
          navigate('/dashboard');
        } else if (userResponse.role === 'COACH') {
          if (metaRoleRespons && metaRoleRespons.role.defaultPath) {
            navigate(metaRoleRespons.role.defaultPath);
          } else {
            navigate('/dashboard');
          }
        } else if (userResponse.role === 'STUDENT') {
          navigate(metaRoleRespons.role.defaultPath);
        } else if (userResponse.role === 'SALES_REP') {
          if (metaRoleRespons && metaRoleRespons.role.defaultPath) {
            navigate(metaRoleRespons.role.defaultPath);
          } else {
            navigate(
              `/${String(userResponse.role).toLocaleLowerCase()}/leaderboard/`
            );
          }
        }
      } else {
        setError(response.data.error);
      }
    } catch (error) {
      setError('Invalid Credentials');
    }
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return;
    }

    setLoading(true);

    try {
      const data = {
        password,
        invitationId,
        organizationCode,
      };

      const response = await adminAPI.organizationUserRegistration(data);

      const userResponse = await getMe();
      await storeUserDataToLocalStorage(userResponse);
      setLoading(false);
      if (response.status === 200 && response.data.success) {
        if (userResponse.role === 'ADMIN') {
          navigate('/dashboard');
        } else if (userResponse.role === 'COACH') {
          navigate(`/dashboard`);
        } else if (userResponse.role === 'STUDENT') {
          navigate('/student/psych/dashboard');
        } else if (userResponse.role === 'SALES_REP') {
          navigate('/sales_rep/leaderboard');
        }
      } else {
        setError(response.data.error);
      }
    } catch (error) {
      setLoading(false);
      setError('Server Error 500. Please contact with Admin');
    }
  };

  const getOrganizationMeta = async () => {
    setLoading(true);
    try {
      const response = await adminAPI.getOrganizationMetaByOrganizationMetaName(
        organizationCode
      );
      setOrganizationMeta(response.data);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getOrganizationMeta();
  }, []);

  return (
    <div className={styles.container}>
      {(loading || isLogginIn) && <LoadingOverlay />}

      <div className={styles.login}>
        <div className={styles.login_container}>
          <div className={styles.nfte_logo}>
            <img src={organizationMeta.loginLogo} alt="Logo" />
            <p className={styles.error}>{error}</p>
          </div>

          {invitationId && <h1>Register Your Account</h1>}

          {invitationId ? (
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center',
                width: '100%',
                '& > :not(style)': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl className={styles.formControl} variant="filled">
                <CustomTextField
                  label="Password"
                  value={password}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  helperText={!passwordMatch ? 'Passwords do not match' : ''}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              <FormControl className={styles.formControl} variant="filled">
                <CustomTextField
                  label="Confirm Password"
                  value={confirmPassword}
                  type={showConfirmPassword ? 'text' : 'password'}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  helperText={!passwordMatch ? 'Passwords do not match' : ''}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              <CustomButton
                disabled={disableButton}
                className={styles.signInButton}
                onClick={handleRegistration}
              >
                Sign Up
              </CustomButton>
            </Box>
          ) : (
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center',
                width: '100%',
                '& > :not(style)': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <CustomTextField
                label="EMAIL ADDRESS"
                placeholder="EMAIL ADDRESS"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                helperText={
                  recipientEmailErrorMessage ? recipientEmailErrorMessage : ''
                }
                error={recipientEmailErrorMessage ? true : false}
              />
              <CustomTextField
                label="PASSWORD"
                placeholder="PASSWORD"
                value={password}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <CustomButton
                disabled={disableButton}
                className={styles.signInButton}
                onClick={handleLogin}
              >
                Log In
              </CustomButton>

              <a
                className={styles.forgetPassword}
                href={`/${organizationCode}/forget`}
              >
                Forgot Password?
              </a>
            </Box>
          )}

          <div className={styles.terms_section}>
            <a href="/terms-and-condition">Terms & Conditions</a>
            <span> and </span>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>

          <p className={styles.orText}>or</p>

          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
      </div>
      {/* <div className={styles.branding}>
        <div className={styles.brainding_section}>
          <h1>This is an extremely exciting title!</h1>
          <h3>
            And here is some extremely exciting body text to go with it. You
            should really schedule a demo. Yeah.
          </h3>
          <a href="#">Schedule Demo</a>
        </div>
      </div> */}
    </div>
  );
};

export default NFTELogin;
