import { useSnackbar } from 'contexts/SnackbarContext';
import { useState } from 'react';

export const useApi = ({ fetcher, onSuccess, successMessage }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const { showSnackbar } = useSnackbar();

  const call = async (...params) => {
    setIsLoading(true);
    setIsError(false);
    setError(null);
    try {
      const res = await fetcher(...params);
      if (res?.data) {
        setData(res.data);
        if (onSuccess) onSuccess(res.data);
        if (successMessage) showSnackbar(successMessage, 'success');
      }
      return res;
    } catch (err) {
      setIsError(true);
      setError(err);
      showSnackbar(err.message || 'An error occurred', 'error');
      console.error('API Call Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, isError, error, call };
};

export default useApi;
