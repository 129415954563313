import AddIcon from '@mui/icons-material/Add';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SendIcon from '@mui/icons-material/Send';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import Person from '../images/person.png';
import styles from './styles/Announcement.module.css';

const AnnouncementCreatePost = ({ setNewAnnouncement }) => {
  const [disableButton, setDisableButton] = useState(true);
  const [announcementTitle, setAnnouncementTitle] = useState('');
  const [announcementBody, setAnnouncementBody] = useState('');

  useEffect(() => {
    if (announcementTitle.length > 0 && announcementBody.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [announcementTitle, announcementBody]);

  return (
    <>
      <div className={styles.create_post}>
        <div className={styles.create_post_header}>
          <h2>Create New Announcement Post</h2>
          <CloseIcon onClick={() => setNewAnnouncement(false)} />
        </div>
        <Box
          component="form"
          sx={{
            width: '100%',
            '& > :not(style)': { mb: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-controlled"
            label="ANNOUNCEMENT POST TITLE"
            inputProps={{ maxLength: 100 }}
            onChange={(e) => setAnnouncementTitle(e.target.value)}
          />
          <TextField
            id="outlined-controlled"
            label="YOUR REPLY"
            placeholder="Type your reply here"
            multiline
            rows={10}
            maxRows={4}
            onChange={(e) => setAnnouncementBody(e.target.value)}
          />
        </Box>
        <div className={styles.publish}>
          <button
            className={disableButton ? styles.disableButton : styles.add_post}
            disabled={disableButton}
            onClick={() => setNewAnnouncement(false)}
          >
            <SendIcon /> Publish Announcement Post
          </button>
        </div>
      </div>
    </>
  );
};

const AnnouncementPost = () => {
  const [selectLike, setSelectLike] = useState(false);
  const [selectComment, setSelectComment] = useState(false);
  const [reply, setReply] = useState(RichTextEditor.createEmptyValue());
  const [expanded, setExpanded] = useState(false);

  const [replyBody, setReplyBody] = useState('');
  const [disableReplyButton, setDisableReplyButton] = useState(true);

  useEffect(() => {
    if (replyBody.length > 0) {
      setDisableReplyButton(false);
    } else {
      setDisableReplyButton(true);
    }
  }, [replyBody]);

  const [reactedPeople, setReactedPeople] = useState([
    { img: Person, name: 'Mr. Kene Turner' },
    { img: Person, name: 'Mr. Kene Turner' },
    { img: Person, name: 'Mr. Kene Turner' },
    { img: Person, name: 'Mr. Kene Turner' },
    { img: Person, name: 'Mr. Kene Turner' },
    { img: Person, name: 'Mr. Kene Turner' },
  ]);

  return (
    <>
      <div className={styles.post}>
        <div className={styles.post_header}>
          <h4>Exciting New Courses and Workshops!</h4>
          <div className={styles.header_right}>
            <ShareOutlinedIcon />
            <span>Share</span>
            <MoreHorizIcon />
          </div>
        </div>
        <div className={styles.post_body}>
          <div className={styles.post_body_head}>
            <div className={styles.post_body_image}>
              <img src={Person} alt="" />
              <div className={styles.post_body_head_left}>
                <div className={styles.profile}>
                  <h4>Mr. Kene Turner</h4>
                  <p>Education Pioneer</p>
                </div>
                <div className={styles.role}>
                  <span>ADMIN</span>
                  <span>STAFF</span>
                </div>
              </div>
            </div>

            <div className={styles.post_date}>
              <h4>September 20, 2021</h4>
              <p>Wednesday • 3:13 AM</p>
            </div>
          </div>
          <div className={styles.post_body_content}>
            <div
              className={
                expanded ? styles.post_content_expanded : styles.post_content
              }
            >
              <p>
                We are thrilled to share some exciting news with you all! TechEd
                Pro is proud to announce a new lineup of cutting-edge courses
                and workshops designed to empower you with the latest skills and
                knowledge in the fast-paced world of technology. Whether you're
                looking to start your tech journey, level up your current
                skills, or specialize in a specific area, TechEd Pro has
                something for you.
              </p>
              <p>What to Expect:</p>
              <ul>
                <li>
                  <p>
                    Fresh Course Offerings: Get ready for brand-new courses
                    covering emerging technologies such as Artificial
                    Intelligence, Blockchain, and Quantum Computing.
                  </p>
                </li>
                <li>
                  <p>
                    Fresh Course Offerings: Get ready for brand-new courses
                    covering emerging technologies such as Artificial
                    Intelligence, Blockchain, and Quantum Computing.
                  </p>
                </li>
                <li>
                  <p>
                    Fresh Course Offerings: Get ready for brand-new courses
                    covering emerging technologies such as Artificial
                    Intelligence, Blockchain, and Quantum Computing.
                  </p>
                </li>
              </ul>
            </div>
            <div className={styles.button_section}>
              <button
                className={styles.buttonExpand}
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? 'EXPANDED' : 'EXPAND'}
              </button>
              {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </div>

            <div className={styles.reactions}>
              <div className={styles.react_sections}>
                <div className={styles.react_section}>
                  {reactedPeople.map((person, index) => (
                    <img src={person.img} alt="" className={styles.react_img} />
                  ))}
                </div>
                <p>Liked by Cadmus, Erebus + 6 others</p>
              </div>
              <div
                className={styles.likeComment}
                onClick={() => setSelectLike(!selectLike)}
              >
                <div className={selectLike ? styles.selectLike : styles.like}>
                  {selectLike ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  <span>Like</span>
                </div>
                <div
                  className={
                    selectComment ? styles.selectComment : styles.comment
                  }
                  onClick={() => setSelectComment(!selectComment)}
                >
                  {selectComment ? (
                    <ChatBubbleIcon />
                  ) : (
                    <ChatBubbleOutlineIcon />
                  )}
                  <span>Comment</span>
                </div>
              </div>
            </div>
            <div className={styles.border_line}></div>

            {selectComment && (
              <>
                <div className={styles.post_reply}>
                  <div className={styles.top}>
                    <div className={styles.student_profile}>
                      <img src={Person} alt="" />
                      <h4>Mr. Kene Turner</h4>

                      <span>Student</span>
                    </div>
                    <div className={styles.date}>
                      <p>September 20, 2021</p>
                      <p>Wednesday • 3:13 AM</p>
                    </div>
                  </div>
                  <p>
                    Mark your calendars and watch this space for more details.
                    Get ready to supercharge your tech skills and boost your
                    career with TechEd Pro!
                  </p>
                </div>
                <div className={styles.post_reply}>
                  <div className={styles.top}>
                    <div className={styles.student_profile}>
                      <img src={Person} alt="" />
                      <h4>Mr. Kene Turner</h4>

                      <span>Student</span>
                    </div>
                    <div className={styles.date}>
                      <p>September 20, 2021</p>
                      <p>Wednesday • 3:13 AM</p>
                    </div>
                  </div>
                  <p>
                    Mark your calendars and watch this space for more details.
                    Get ready to supercharge your tech skills and boost your
                    career with TechEd Pro!
                  </p>
                </div>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 2, width: '100%', marginLeft: '0' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-controlled"
                    label="YOUR REPLY"
                    placeholder="Type your reply here"
                    multiline
                    rows={5}
                    maxRows={4}
                    onChange={(e) => setReplyBody(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ marginTop: '4rem' }}
                        >
                          <Button
                            className={
                              disableReplyButton
                                ? styles.disableReplyButton
                                : styles.reply
                            }
                            variant="outlined"
                            style={{ fontWeight: 'bold' }}
                            disabled={disableReplyButton}
                          >
                            <SendOutlinedIcon /> Post Reply
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </>
            )}
          </div>
        </div>
        {/* <div className={styles.post_footer}>
          <div className={styles.post_footer_left}>
            <p>Posted by</p>
            <p>Date</p>
          </div>
          <div className={styles.post_footer_right}>
            <button className={styles.post_btn}>View</button>
          </div>
        </div> */}
      </div>
    </>
  );
};

const Announcement = () => {
  const [newAnnouncement, setNewAnnouncement] = useState(false);

  return (
    <>
      <div className={styles.header}>
        <h4>Announcements</h4>
        <div className={styles.header_right}>
          <button
            className={styles.add_post}
            onClick={() => setNewAnnouncement(true)}
          >
            <AddIcon />
            New Announcement Post
          </button>
        </div>
      </div>
      <div className={styles.border_line}></div>
      {newAnnouncement && (
        <AnnouncementCreatePost setNewAnnouncement={setNewAnnouncement} />
      )}

      <AnnouncementPost />
      <AnnouncementPost />
    </>
  );
};

export default Announcement;
